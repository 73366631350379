@font-face {
    font-family: 'Etelka Text Pro';
    src: url('EtelkaTextPro-Bold.eot');
    src: url('EtelkaTextPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('EtelkaTextPro-Bold.woff2') format('woff2'),
        url('EtelkaTextPro-Bold.woff') format('woff'),
        url('EtelkaTextPro-Bold.ttf') format('truetype'),
        url('EtelkaTextPro-Bold.svg#EtelkaTextPro-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

