// Variables
@import 'variables';

// Multiselect dropdown
//@import '~vue-multiselect/dist/vue-multiselect.min.css';

// Bootstrap
//@import '~bootstrap/scss/bootstrap.scss';
//@import '~bootstrap-vue/src/index.scss';
//@import 'bootstrap-vue/src/icons.scss';

@import 'bootstrap-vue/dist/bootstrap-vue.css';

@media (min-width: 576px) {
    .modal-xl {
        max-width: 90% !important;
    }
}

#dropzone strong {
    color: #fff !important;
}
