////////////////////////////////////////////////////////////////////////////////////////////////////////////
// ISC3.org framework.scss
// Author: Nils Grassmann
// Copyright: Style & Grace – Design & Advertising (2021)
////////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// Table of Contents
////////////////////////////////////////////////////////////////////////////////////////////////////////////

// 1.) ISC3 Classes
//
// 2.) Bootstrap Overrides
//  2.1) Buttons & Links
//  2.2) Links with Icons
//  2.3) Modals
//  2.4) Forms & Input
//
// 3.) CSS Animations Presets
//
// X.) LAB (Experimental Code)

////////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// 1.) ISC3 Classes
////////////////////////////////////////////////////////////////////////////////////////////////////////////

// html {
//     // scroll-behavior: smooth; // Probleme mit JS Scrolls in Chrome
// }


// html {
//     scroll-behavior: smooth;
// }

html {
    scroll-behavior: smooth;
}
html#atlas,
html#events {
    scroll-behavior: auto !important;
}

main {
    margin-bottom: 6em;
}

// Lazy Loader IMG Settings
section img {
    width: 100%;
    height: auto;
}

// 3.4.) Articles

// Gerneral Articles
html main article {
    margin-top: 3em;
    margin-bottom: 3em;

    section {
        margin-top: 2em;
        margin-bottom: 2em;
        width: 100%;
        &:first-child {
            margin-top: 0;
        }
        &:last-child{
            margin-bottom: 0;
        }

        p:last-child,
        ul:last-child {
            margin-bottom: 0;
        }

        .col, [class*="col-"] {
            margin-bottom: 0;
        }
        @media screen and (max-width:768px) {
            .col, [class*="col-"] {
                margin-bottom: 25px;
            }
            .col:last-child,
            [class*="col-"]:last-child {
                margin-bottom: 0;
            }
        }

    }

    .col, [class*="col-"] {
        margin-bottom: 25px;
    }


    section.article-intro {
        margin-bottom: 4em;
    }

    section .row.no-gutters {
        .col, [class*="col-"] {
            margin-bottom: 0 !important;
        }
    }
    .section-body {
        margin-top: 0.5em;
        margin-bottom: 0.5em;
    }
    .section-footer {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        p {
            margin-bottom: 0;
            margin-top: 0;

        }
    }

    .col, [class*="col-"] {
        margin-bottom: 25px;
    }




    figure {
        margin-bottom: 25px;
        &:last-of-type {
            margin-bottom: 0;
        }
    }
    figure img {
        display: inline-block;
        margin: 0;
        width: 100%;
        height: auto;
    }

    figure.mw-560 {
        max-width: 560px;
        margin-left: auto;
        margin-right: auto;
    }

    figure.mw-280 {
        max-width: 280px;
        margin-left: auto;
        margin-right: auto;
    }

    a.btn {
        display: inline-block;
        margin-top: 1em;
        color: white !important;
        width: auto;
        &.width-100 {
            width: 100%
        }
        &:empty {
            display: none;
        }
    }
}

html main article:first-of-type {
    margin-top: 6em;
}

html main article:last-of-type {
    margin-bottom: 0;
}


.article-footer,
.section-footer {
    padding-top: 0 !important;
    a.btn {
        margin-top: 0 !important;
    }
}


html main article.intro {
   text-align: center;
}


article.bg-blue {
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 5px;
    background: none !important;
    .container {
        @include bg-blue;
        @include text-white;
        padding: 50px 15px;
        @media screen and (min-width:768px) {
            padding: 50px;
        }
        border-radius: 5px;
        a {
            color: white !important;
            font-weight: 900;
        }
        a.btn {
            background-color: white;
            color: $isc3-blue !important;
            width: auto;
        }
        .text-underline:after {
            background-color: white;
        }

        blockquote {
            color: white;
        }

    }
}

article.dark-blue,
article.bg-blue.dark-blue {
    background: none;
    @include text-white;
    .container {
        background-color: $isc3-darker-blue !important;
    }
    a {
        color: white !important;
        font-weight: 900;
    }
    a.btn {
        background-color: white;
        color: $isc3-blue !important;
        width: auto;
    }
    .text-underline:after {
        background-color: white;
    }
    blockquote {
        color: white;
    }
}

article.full-width {
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: $isc3-blue;
    border-radius: 0 !important;
    margin-left: 0;
    margin-right: 0;
    .container {
        @include text-white;
        a {
            color: white !important;
        }

        a.btn  {
            color: $isc3-blue !important;
            width: auto;
        }
        .text-underline:after {
            background-color: white;
        }
    }
}

article.full-width.bg-blue {
    background-color: $isc3-blue !important;
}

article.full-width.bg-blue.dark-blue,
article.full-width.dark-blue {
    background-color: $isc3-darker-blue !important;
}

article.full-width.divider.visual {
    background: url('/img/hero_img_gradient.jpg') no-repeat;
    background-position: center center;
    background-size: cover;
    height: auto;
    min-height: 300px;
    position: relative;

    section {

        h2 {
            color: $isc3-darker-blue !important;
        }
        h3 {
            font-family:'ubuntu', Arial, Helvetica, sans-serif, sans-serif;
            color: white !important;
            font-weight: 100 !important;
        }
    }
}

article.highlight {
    margin-left: 15px;
    .container {
        border-left: solid 4px $isc3-blue;
        padding-left: 1.15em;
        padding-bottom: .25em;
    }
}

article.expanding {
    .article-header {
        padding-bottom: 0.5em!important;
    }
    .article-collapse {
        padding-left: 15px;
        padding-right: 15px;

        figure {
            padding-top: 15px;
            padding-bottom: 15px;
        }
    }
    .col, [class*="col-"] {
        margin-bottom: 0;
    }
    .article-footer {
        margin-top: 0.5em;
        padding-top: 0 !important;
        a.btn {
            margin-top: 0 !important;
        }
    }


}

article.img-50 {
    figure {
        margin-bottom: 25px !important;
    }
    figure:last-of-type  {
        margin-bottom: 0 !important;
    }

}

article.carousel,
.carousel {
    .carousel-caption {
        color: white !important;
        h5 {
            color: white !important;
        }
        background-color: rgba($isc3-blue, 0.85);
        left: 0;
        right: 0;
        bottom: 0;
        padding-bottom: 26px;
        padding-left: 10px;
        padding-right: 10px;
    }
    .carousel-control-prev,
    .carousel-control-next {
        font-size: 1em;
        @media screen and (min-width:768px) {
            font-size: 2em;
        }
        i {
            color: white !important;
        }
    }

    .carousel-control-prev:hover,
    .carousel-control-prev:active,
    .carousel-control-prev:visited
     {
        i {
            animation: bounce_left .5s ease-in-out infinite;
        }
    }
    .carousel-control-next:hover,
    .carousel-control-next:active,
    .carousel-control-next:visited {
        i {
            animation: bounce_right .5s ease-in-out infinite;
        }
    }



    ol.carousel-indicators {
        bottom: 0;
        margin-bottom: 0;
        li {
            list-style-type: none !important;
        }
    }
}

article.carousel.video,
.carousel.video {

    .carousel-indicators {
        li.active {
            position: relative;
            top: -1px;
            height: 5px;
            width: 50px;
        }
    }
    .carousel-inner .carousel-item {
        .carousel-caption {
            padding-bottom: 2.5rem;

            h5 {
                margin-bottom: 0.2rem;
            }
            p {

                line-height: 1.25;
                // &:first-of-type {
                //     margin-top: 0rem;
                // }
            }
        }

         .icn {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100px;
            height: 100px;
            transform: translate(-50%,-50%);
            // cursor: pointer;
            display: inline;
            background-image: url("../sass/img/icn_bg_circles_white.svg");


            &::before {
                font-family: 'Font Awesome 5 Free';
                content: "\f028";
                font-weight: 900;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                color: $isc3-blue;
            }

            &.btn-play {
                &::before {
                    font-size: 2.5rem;
                    padding-left: 10%;
                    content: "\f04b";
                }
                // &.active::before {
                //     content: "\f04b";
                // }
            }
        }
    }
    @media screen and (max-width: 768px) {
        .carousel-caption {
            // padding-bottom: 2.5rem;
            padding-top: 5px;
            padding-bottom: 10px !important;
            h5 {
                font-size: 0.8em;
                margin-bottom: 0.25em;
            }
            p {
                margin-top: 0;
                font-size: 0.75em;
                margin: 0;
                line-height: 1.35;
            }
        }

        .carousel-indicators {
            display: none;
        }
    }
}

article.further-resources {
    .link-cont {
        @media screen and (min-width:992px) {
            min-height: 200px;
        }
        padding: 15px;
        background-color: $isc3-blue;
        border-radius: 5px;
        a {
            position: relative;
            display: block;
            color: white !important;
            line-height: 1.25;
            margin-bottom: 0.5em;
            margin-left: 1.5em;
            font-weight: 400;
        }
        a::before {
            position: absolute;
            left: -1.45em;
            top: 0.15em;
        }
        a:last-of-type {
            margin-bottom: 0;
        }
        a:hover,
        a:active {
            color: white !important;
            font-weight: 400;
        }
        // .text-underline {
        //     margin-bottom: 0.55em;
        // }
        .text-underline::after{
            margin: 0.5rem auto 0.65rem 0;
            height: 1px;
            width: 100%;
            background-color: white;
        }
    }
}

article.quicklinks {
    margin-bottom: -6em !important; // to equalize main margin (6em)
    .row {
        margin-bottom: -15px;
    }

    .col, [class*="col-"] {
        padding-bottom: 15px !important;
        margin-top: 30px !important;
        margin-bottom: 30px !important;


        div:first-of-type {
            width: calc(100% - 2.5em);
            padding-left: 5px;
        }
        div:last-of-type {
            width: 2.5em;
            i {
                font-weight: 900;
                font-size: 2em;
            }
        }
    }

    .col:hover , [class*="col-"]:hover {
        cursor: pointer;
    }

    .col:hover i, [class*="col-"]:hover i {
        animation: bounce_down .5s ease-in-out infinite;
    }

   .col::after, [class*="col-"]::after {
        content: " ";
        width: calc(100% - 30px);
        height: 1px;
        margin-left: auto;
        margin-right: auto;
        background-color: white;
        position: absolute;
        bottom: 0;
        left: 15px;

    }
}

article.related-content,
section.related-content {
    margin-bottom: -6em !important; // to equalize main margin (6em)
    line-height: 1.25em;
    // @media screen and (min-width:768px) {
        padding-bottom: 35px;
    // }

    .row {
        margin-bottom: -25px;
        .col, [class*="col-"] {
            margin-bottom: 25px;
        }
    }

    a {
        height: 100%;
        color:$isc3-blue;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        @media screen and (min-width:768px) {
            margin-bottom: 15px;
        }

        .text-cont{
            outline: 1px solid white;
            outline-offset: -1px;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            margin-top: auto;
            margin-bottom: auto;
            flex: 1 1 auto;

            div:first-of-type {
                padding: 15px;
                padding-right: 0;
                width: 80%;
                // height: 100%;
                // margin-top: auto;
                // margin-bottom: auto;
                // vertical-align: middle;
            }

            div:last-of-type {
                text-align: center;
                width: 15%;
                i {
                    font-size: 1.5em;
                }
            }
        }
    }
    a:hover i {
        animation: bounce_right .5s ease-in-out infinite;
    }
}

bg-blue.article.related-content,
bg-blue section.related-content {
    a {
        color:white;
    }
    .text-cont{
        outline: 1px solid white;
    }
}


// Welcome Balde Specific Article
article.cards {
    .row:last-of-type {
        margin-bottom: -25px
    }
    .card {
        padding: 25px 25px 50px 25px;
        border: none;
        border-radius: 5px;

        // @media screen and (min-width:768px) {
        //     margin-bottom: 25px;
        // }

        height: 100%;
        .card-header {
            border: 0;
            background: none;
        }
        .card-body {
            padding-top: 25px;
            padding-bottom: 25px;
        }
        .card-footer {
            border: 0;
            background: none;
            padding: 0;
            display: flex;
            justify-content: center;
        }
        a.btn {
            margin-top: 0 !important;
        }
    }
}

article.image-cards {
    .section-header {
        padding: 30px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 25px;
        .text-underline {
            margin-bottom: 0;
        }
        .text-underline::after {
            margin-bottom: 0;
        }
    }
    .card {
        padding: 0 0 50px 0;
        .card-header {
            padding: 0;
            position: relative;
            img {
                align-self: flex-start !important;
                width: 100%;
                height: auto;
                border-bottom: solid 4px $isc3-blue;
                border-top-right-radius: 5px;
                border-top-left-radius: 5px;
                border: none;

                // for IE 11
                flex-shrink: 0;

                // IE Fix
                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                    background-color: $isc3-blue;
                }
            }
            .icn {
                position: absolute;
                top: 100%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                width: 140px;
                height: 140px;
                background-image: url("../sass/img/icn_bg_circles_white.svg");
                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    width: 75px;
                    height: auto;
                }
            }
        }
        .card-body {
            padding: 50px 25px 25px 25px;
        }
    }

}
article.image-cards.icons {
    .card-body {
        padding-top: 90px;
    }
}
article.image-cards.cards-sm {

    .row:last-of-type {
        margin-bottom: -25px;
    }

    .col, [class*="col-"] {
        margin-bottom: 25px;
    }

    .card {
        h4 {
            margin-bottom: 0;
        }

        padding-bottom: 25px;
        .card-body {
            padding-top: 25px !important;
            // margin-top: .25em;
        }
    }
}

article.image-cards.cards-sm.icons {

    .training-anchor {
        position: relative;
        top: -140px;
    }

    @media screen and (min-width:768px) {
        h4 {
            display: flex;
            flex-direction: column;
            // justify-content: center;
            // min-height: 10rem;
            margin-bottom: 0.25rem;
        }
    }
        // .subline {
        //     display: inline-block;
        //     min-height: 4rem;
        //     vertical-align: center;
        // }

    .info {

        p.type, p.duration, p.certificate {
            margin-left: 1.65rem;
            position: relative;
            margin-bottom: 0;
            line-height: 1.5;
            &::before {
                font-family: 'Font Awesome 5 Free';
                font-weight: 900;
                position: absolute;
                left: -1.65rem;
            }
            &:empty {
                display: none;
            }
            .type-el {
                display: block;
                margin-left: 0;
                margin-top: 0.35rem;
            }
            // select second span (first type-el), type-el:first-of type not possible on classnames
            span:nth-child(2) {
                display: inline;
            }
        }

        p.type::before {
            content: "\e53d";
        }
        p.duration::before {
            content: "\f017";
        }
        p.certificate::before {
            content: "\f0a3";
        }
    }

    .modal {
        h3{
            margin-bottom: 1rem;
        }
        h4 {
            display: inline;
        }
        .info {
            padding-left:1rem;
        }
        a {
            color: white;
            font-weight: 900;
        }
    }


    .icn {
        width: 110px;
        height: 110px;
        img {
            width: 60%;
        }
    }

    .card-body {
        padding-top: 65px !important;
    }



}


article.icn-left {
    section {
        margin-top: 4em;
        margin-bottom: 4em;
        &:last-child {
            margin-bottom: 0;
        }
        .icn-cont {
            width: 100%;
            text-align: center;
            padding-bottom: 0px;
            margin-bottom: 10px;

            @media screen and (min-width:992px) {
                margin-bottom: 0 !important;
                border-right: solid 3px $isc3-blue !important;
            }

            span.icn {
                display: inline-block;
                width: 125px;
                height: 110px;
            }
        }
    }
    section.icn-startup {
        span.icn {
            background-image: url(../sass/img/icn_rocket.svg);
        }
    }

    section.icn-event {
        span.icn {
            background-image: url(../sass/img/icn_calendar.svg);
        }
    }

    section.icn-news {
        span.icn {
            background-image: url(../sass/img/icn_news.svg);
        }
    }

    section.icn-startup.green{
        span.icn {
            background-image: url(../sass/img/icn_rocket_green.svg);
        }
    }

    section.icn-event.green {
        @media screen and (min-width:768px) {
            // .icn-cont {
            // border-bottom: none !important;
            // border-right: solid 3px $isc3-green !important;
            // }

        }
        span.icn {
            background-image: url(../sass/img/icn_calendar_green.svg);
        }
    }
}

article.cards-blue {
    .card {
        background-color: $isc3-blue;
        @include text-white;
    }
    a.btn {
        margin-top: 0 !important;
    }
}

article.signin {
    margin-bottom: -6em !important; // to equalize main margin (6em)
    .col:first-of-type, [class*="col-"]:first-of-type {
        // padding-bottom: 1.5rem;
        // border-bottom: solid 2px white;
        margin-bottom: 0;
        padding-bottom: 25px;
        // @media screen and (min-width:768px) {
        //     padding-bottom: 0.5rem;
        //     border-bottom: none;
        //     border-right: solid 2px white;
        //     padding-right: 30px;
        // }
    }
    .col:first-of-type::after, [class*="col-"]:first-of-type::after {
        content: " ";
        width: calc(100% - 30px);
        height: 2px;
        background-color: white;
        margin-top: 30px;
    }
    .col:last-of-type, [class*="col-"]:last-of-type {
        padding-bottom: 0;
        margin-bottom: 0;
    }
    @media screen and (min-width:768px) {
        .col:first-of-type, [class*="col-"]:first-of-type {
            // padding-bottom: 1.5rem;
            padding-bottom: 0.5rem;
            border-bottom: none;
            border-right: solid 2px white;
            padding-right: 30px;
        }
        .col:first-of-type::after, [class*="col-"]:first-of-type::after {
            content: none;
        }
        .col:last-of-type, [class*="col-"]:last-of-type {
            padding-top: 0;
            padding-bottom: 0.5rem;
            padding-left: 30px;
            padding-right: 30px;
        }
    }
    a.btn {
        margin-top: 0 !important;
    }
}

// Site specific Articles
article.full-width.cards {
    padding-top: 65px;
    padding-bottom: 0;
}

.double-article article {
        columns: 300px 2; // 300px width, 2 columns
        column-gap: 30px;
}

.team-article,
.team-section,
.network-section,
.network-article,
.partner-article,
.partner-section {
    .col, [class*="col-"] {
        margin-bottom: 0 !important;
    }

    // margin-bottom: 3em;
    .section-body {
        margin-top: 0;
        margin-bottom: 1em;
    }
    .no-overflowX {
        margin-left: auto;
        margin-right: auto;
        max-width: fit-content;
        // background-color: aquamarine;
        @media screen and (max-width:768px) {
            margin-bottom: 1em;
        }

        img {
                margin-bottom: 0 !important;
        }

    }

    .article-img {
        img {
            display: block;
            max-width: 225px;
            height: auto;
            margin-left: auto;
            margin-right: auto;
            // max-height: 150px;
            @media screen and (max-width:768px) {
                margin-bottom: 1em;
            }
        }
    }
    a.btn {
        margin-top: 0;
    }
    .section-footer {
        p, a.btn  {
            margin: 0;
        }
    }
}
.team-section,
.team-article {
    // if img = empty
    img[src=""] {
        //load avatar img
        background: no-repeat center center url("img/portrait-placeholder.jpg");
        background-size: cover;
        //hide alt text
        color: rgba(0,0,0,0);
        width: 200px;
        height: 260px;
    }

    .section-body {
        margin-bottom: 0;
    }
}
.network-section,
.network-article {
    img {
        max-width: 200px !important;
        height: auto;
    }
}

article.speakers {
    .speaker {
        width:150px;
        height: auto;
        display: inline-block;
        margin: 0 10px 10px 0;
        position: relative;
        height: 288px;
        .speaker-img {
            width: 100%;
            height: auto;
            border-radius: 5px 5px 0 0;
            position: absolute;
            top:0;
            left: 0;
        }
        .speaker-txt {
            background: $isc3-blue;
            padding:5px 10px;
            color:white;
            border-radius: 0 0 5px 5px;
            position: absolute;
            bottom:0;
            left: 0;

            .speaker-name {
                font-weight:600;
                line-height: 1.25!important;
                display: inline-block;
            }
            .speaker-orga {
                font-size: 0.85em;
                font-weight: 200;
                line-height: 1.35;
                display: inline-block;
            }
        }
    }
}

article.full-height {
    margin-top: 3em;
    margin-bottom: 3em;
    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: calc(100vh - 640px - 3em);
        @media screen and (min-width:$tablet) {
            min-height: calc(100vh - 435px - 3em);
        }
        @media screen and (min-width:$desktop) {
            min-height: calc(100vh - 440px - 3em);
        }
    }
}

article.newsletter-opt {
    .icn-xl {
        font-size:5em;
        color: $isc3-blue;
    }
}

.copyright {
    /*display:none!important;*/
    background-color: rgba($isc3-darker-blue, 0.55);
    position: absolute;
    bottom: 10px;
    transform: translateX(-65px);
    color: white;
    padding: 5px 15px 5px 75px;
    width: max-content;
    max-width: calc(100% + 50px);
    font-size: 0.75em;
    line-height: 1.5;

    /*Add copyright C*/
    &::before {
        content:"\00a9 \00a0";
        white-space: normal;
    }

    /*dont show if empty*/
    &:empty {
        display: none;
    }
}



#hero.img-hero .copyright,
#hero.video-hero .copyright,
.carousel .copyright {
    z-index: 4;
    top: 10px;
    bottom: auto;
    height: auto;
}

.no-overflowX {
    position: relative;
    overflow-x: hidden;
}


///// Page Builder Classes ////

// sidebar
#options-sidebar {
    .panel {
        &.bg-blue {
            margin-top: 1em;
            margin-bottom: 2em;
            padding: 15px;
            padding-top: 20px;
            background-color:$isc3-blue;
            color:white !important;
            h4, h5 {
                color: white;
            }
            li {
                line-height: 1.35em;
            }
            li::before {
                color: white;
            }
        }
    }

    legend, label {
        font-weight: 600;
        color: $isc3-blue;
    }

    .container-fluid {
            margin-top: 52px;
    }

    textarea {
        max-height: 1200px!important;
    }

}


// Template Select
.preview_box .preview_title {
    line-height: 1.25em;
}


// Footer
footer {
    width: 100%;
    padding: 25px 0;
    // margin-top: 6em;
    color: white;
    text-align: center;

    background-color: $isc3-darker-blue;
    border-top: solid 6px $isc3-blue;

    [class*="col-"]:first-of-type {
        margin-bottom: 1em;
    }

    @media screen and (min-width:768px) {
        [class*="col-"]:first-of-type {
            text-align: left;
            margin-bottom: 0;
        }

        [class*="col-"]:last-of-type {
            text-align: right;
        }
    }
}

// aditional Styling Classes
.bg-blue {
    background-color: $isc3-blue !important;
}

.bg-white {
    background-color: white;
    color: $isc3-blue !important;
}

    // element align Classes
.vertical-center {
    margin-top: auto;
    margin-bottom: auto;
}

.vertical-bottom {
    margin-top: auto;
    margin-bottom: 0;
}

.vertical-top {
    margin-top: 0;
    margin-bottom: auto;
}

.horizontal-center {
    margin-left: auto;
    margin-right: auto;
}

.horizontal-left {
    margin-right: auto;
    margin-left: 0;
}

.horizontal-right {
    margin-right: 0;
    margin-left: auto;
}

.center-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.full-height {
    height: 100%;
}

.full-width {
    width: 100%;
}


.desaturate {
    filter: grayscale(100%);
}
.desaturate.btn-active,
.desaturate:hover {
    filter: none;
}

.hidden {
    display: none !important;
}

.isc3_logo {
    width: 200px;
    height: auto;
    padding-left: calc(180px * 0.175);
    @media screen and (min-width:768px) {
        width: 250px;
        padding-left: calc(250px * 0.175);
        height: auto;
    }
}


// Element Style Classes
.boxed {
    padding-top: 3em;
    padding-bottom: 3em;
    padding-left: 15px;
    padding-right: 15px;
    @media screen and (min-width:768px) {
        padding-left: 30px;
        padding-right: 30px;
    }
}

.rounded {
    border-radius: 5px !important;
}

.shadow {
    box-shadow: $isc3-box-shadow !important;
}

.line-bottom-mob::after {
    display: block;
    content:" ";
    height: 2px;
    width: 100%;
    position: relative;
    top: 37px;
    background-color: white;
    @media screen and (min-width:768px) {
        content: none;
    }
}

// reduces max-width on Desktop
@media screen and (min-width:950px) {
    .mw-950 {
        display: block;
        max-width: 950px;
        margin-left: auto !important;
        margin-right: auto !important;
    }

    .card-img .mw-950 {
        margin-left: auto !important;
    }
}

img.isc3-logo,
img.graph-img {
    display: block;
    width: 50vw;
    max-width: 250px;
    height: auto;

    margin-left: auto;
    margin-right: auto;
}
img.graph-img {
    width: 75vw;
    max-width: 500px;
}

figure {
    position: relative;
    // IE Hack
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        display: table;
    }

    figcaption {
        // display: table;
        caption-side: bottom;
        vertical-align: center;
        font-size: 0.75em;
        line-height: 1.5em;
        color: $isc3-blue !important;
        // background-color: $isc3-dark-blue;
        width: 100%;
        padding: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        outline: 1px solid $isc3-blue;
        outline-offset: -1px;
    }
    figcaption.large {
        font-size: 1.5em;
        line-height: 1.25em;
        font-weight: 900;
        text-align: center;
        vertical-align: center;
        padding-top: 10px;
        padding-bottom: 10px;
        hyphens: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

.bg-blue figure,
.bg-green figure,
.full-width figure {
    figcaption {
        color: white !important;
        outline-color: white;
    }
}



.youtube-wrapper {
    max-width: 768px;
    margin-left: auto;
    margin-right: auto;

    .youtube-iframe {
        position: relative;
        // margin-top: 2em;
        padding-bottom: 56.25%; /*Equals 16:9*/
        padding-top: 0;
        height: 0;
        overflow: hidden;

        iframe,
        object,
        embed {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

    }
}


.alert {
    line-height: 1.35;
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    // border: 1px solid transparent;
    // border-top-color: transparent;
    // border-right-color: transparent;
    // border-bottom-color: transparent;
    // border-left-color: transparent;
    border-radius: 0.25rem;
}

.alert-success {
    color: white;
    background-color: $isc3-blue;
    border-color: #c3e6cb;
  }


.divider {
    margin-top: 50px;
    margin-bottom: 50px;

    width: 100%;
    border-bottom: solid 4px $isc3-darker-blue;
}

.divider-sm {
    margin-top: 25px;
    margin-bottom: 25px;
    width: 50%;
    border-bottom: solid 2px $isc3-blue;
}

.divider-xs {
    margin-top: 15px;
    margin-bottom: 15px;
    width: 25%;
    border-bottom: solid 1px $isc3-blue;
}



////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// 2.) Bootstrap Additions & Overrrides
////////////////////////////////////////////////////////////////////////////////////////////////////////////


.pb-05 {
    padding-bottom: 0.125rem;
}



////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// 2.1.) Buttons & Links
////////////////////////////////////////////////////////////////////////////////////////////////////////////

//emove Bootstrap Button Focus
.btn:focus,
button:focus,
.btn:active,
button:active,
a:focus,
a:active {
    outline: none;
    box-shadow: none;
    cursor: pointer;
    border-radius: 3px;
}

// Buttons
.btn,
.btn:hover {
    font-size: 1em;
    border: none;
    color: white;
    background-color: $isc3-blue;
    transition: none;
    transition: color .15s ease-in-out;
    box-shadow: .15s ease-in-out;
    user-select: none;

    -webkit-touch-callout: none;
    border-radius: 3px;
}
html.non-touch .btn:hover,
.btn:focus, .btn-active,
a.btn:focus, label.btn:focus,
button.btn:focus {
    color: white;
    background-color: $isc3-dark-blue;
    box-shadow: $isc3-shadow-btn-hover;
}

.btn:active, .btn-active,
a.btn:active, label.btn:active,
button.btn:active,
.show > .btn-primary.dropdown-toggle,
.btn-primary:not(:disabled):not(.disabled):active,

.show > .btn-primary.dropdown-toggle,
.btn-primary:not(:disabled):not(.disabled):active  {
    background-color: $isc3-dark-blue !important;
    outline: none !important;
    box-shadow: $isc3-shadow-btn-active !important;
    cursor: pointer;
}

.btn:visited,
a.btn:visited, label.btn:visited,
button.btn:visited,
.show > .btn-primary.dropdown-toggle,
.btn-primary:not(:disabled):not(.disabled):visited,
.btn:visited,
a.btn:visited, label.btn:visited,
button.btn:visited,
.btn-primary:not(:disabled):not(.disabled):visited  {
    background-color: $isc3-blue !important;
    outline: none !important;
    box-shadow: none !important;
}

button::-moz-focus-inner,
.btn::-moz-focus-inner,
button::-moz-inner,
.btn::-moz-inner {
    border: none !important;
    outline: none !important;
}

.btn-outline,
.btn-outline:hover {
    color: $isc3-blue!important;
    background: none !important;
    border: 2px solid $isc3-blue;
    padding: .375em .75em;
}
html.non-touch .btn-outline:hover {
    color: white!important;
    background-color: $isc3-dark-blue!important;
    border: none!important;
    padding: calc(.375em + 2px) calc(.75em + 2px)!important;
}
.btn-outline:active {
    color: white!important;
    background-color: $isc3-dark-blue!important;
    border: none!important;
    padding: calc(.375em + 2px) calc(.75em + 2px)!important;
}

.btn-blue,
html.non-touch .btn-blue.btn-outline:hover  {
    color: white !important;
}
.btn-blue.btn-outline {
    color: $isc3-blue !important;
}

.btn:empty,
a:empty {
    display: none;
}


// .btn-green,
// html.non-touch .btn-green.btn-outline:hover  {
//     color: white !important;
//     background-color: $isc3-green;
// }
// .btn-green.btn-outline {
//     color: $isc3-green !important;
// }

.btn-green,
.btn-green:hover {
    font-size: 1em;
    border: none;
    color: white;
    background-color: $isc3-green;
    transition: none;
    transition: color .15s ease-in-out,
    background-color .15s ease-in-out,
    box-shadow .15s ease-in-out;
    user-select: none;
    -webkit-touch-callout: none;
    border-radius: 3px;
}
html.non-touch .btn-green:hover {
    color: white;
    background-color: $isc3-dark-green;
    box-shadow: $isc3-shadow-btn-hover;
}

.btn-green:active, .btn-active,
a.btn-green:active, label.btn:active,
button.btn-green:active,
.show > .btn-primary.dropdown-toggle,
.btn-primary:not(:disabled):not(.disabled):active {
    background-color:  $isc3-dark-green;
    outline: none !important;
    box-shadow: $isc3-shadow-btn-active !important;
    cursor: pointer;
}

.btn-white,
html.non-touch .btn-white:hover,
.btn-white:active {
    color: $isc3-blue !important;
    background-color: white !important;
}


.btn-outline.btn-white,
html.non-touch .btn-outline.btn-white:hover {
    color: white !important;
    border: 2px solid white;
    background: none;
}
html.non-touch .btn-outline.btn-white:hover,
.btn-outline.btn-white:active {
    color: $isc3-blue !important;
    border: none;
    padding: calc(.375em + 2px) calc(.75em + 2px);
    background-color: white !important;
}

// .btn-sm {
//     padding: calc(.375em - 2px) calc(.75em);
// }
.btn-sm.btn-outline {
    border: solid 2px;
    padding: calc(.375em - 4px) calc(.75em);
}
html.non-touch .btn-sm.btn-outline:hover,
.btn-sm.btn-outline:active {
    padding: calc(.375em - 2px) calc(.75em + 2px)!important;
}

.btn-xs {
    padding: calc(.375em - 3px) calc(.75em - 4px);
}
.btn-xs.btn-outline,
html.non-touch .btn-xs.btn-outline:hover {
    padding: calc(.375em - 5px) calc(.75em - 4px);
}
html.non-touch .btn-xs.btn-outline:hover,
.btn-xs.btn-outline:active {
    padding: calc(.375em - 3px) calc(.75em - 2px) !important;
}


// hrefs with icons
.btn.search::before,
a.search::before,
a.back::before{
    font-family: 'Font Awesome 5 Free';
    content: "\f002";
    display: inline-block;
    font-size: 1.1em;
    line-height: 1em;
    padding: 0;
    font-weight: 600;
    margin-right: 0;
}

// Links
a.back::before {
    content: "\f137";
    padding-bottom: 2px;
}
a.back::before {
    content: "\f137";
}

html.non-touch a.back:hover,
html.non-touch a.new_psswd:hover {
    text-decoration: none;
}
a.back:hover::before {
    animation: bounce_left .5s ease-in-out infinite;
}

a.back {
    margin-left: 0.4em;
}

a.dropdown-item:hover {
    background-color: $isc3-blue;
    color: white !important;
}

.btn-icn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: $isc3-blue;
    margin-bottom: 10px;
    white-space: unset;
    text-align: left;
    padding: 5px 15px;

    &:last-of-type {
        margin-bottom: 0;
    }

    .icn {
        width: 25px;
        font-weight: 600;
        i {
        font-size: 1em;
        }
    }
    .text {
        width: 100%;
        text-align: left;
        line-height: 1.25;
        margin-left: 0;
        word-break: break-word;
    }
}

.btn-icn.icn-ani-punmp:hover {
    i {
        animation: pump 1s ease-in infinite;
    }
}


////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// 2.2.) Links with Icons
////////////////////////////////////////////////////////////////////////////////////////////////////////////
a.icn::before,
button.icn::before,
span.icn::before {
    font-family: 'Font Awesome 5 Free';
    content: " ";
    display: inline-block;
    font-size: 1em;
    // line-height: 1em;
    font-weight: 900;
    margin-right: .25em;
    vertical-align: baseline;
    backface-visibility: hidden;
    transform: translateZ(0);
    -webkit-font-smoothing: subpixel-antialiased;
}



a.icn-globe::before,
button.icn-globe::before,
span.icn-globe::before,
li.icn-globe::before {
    content: "\f57c";
}

a.icn-page::before,
button.icn-page::before,
span.icn-page::before {
    content: "\f15b";
}

a.icn-calendar::before,
button.icn-calendar::before,
span.icn-calendar::before {
    content: "\f073";
}

a.icn-arrow-right::before,
button.icn-arrow-right::before,
span.icn-arrow-right:before {
    content: "\f054";
}
a.icn-arrow-left::before,
button.icn-arrow-left::before,
span.icn-arrow-left:before {
    content: "\f053";
}

a.icn-arrow-up::before,
button.icn-arrow-up::before,
span.icn-arrow-up:before {
    content: "\f077";
}
a.icn-arrow-down::before,
button.icn-arrow-down::before,
span.icn-arrow-down:before {
    content: "\f078";
}

a.icn-question::before,
button.icn-question::before,
span.icn-question::before {
    content: "\f128";
}

a.icn-phone::before,
button.icn-phone::before,
span.icn-phone::before,
li.icn-phone::before {
    content: "\f095";
}

a.icn-mobile::before,
button.icn-mobile::before,
span.icn-mobile::before {
    content: "\f10b";
}

a.icn-fax::before,
button.icn-fax::before,
span.icn-fax::before {
    content: "\f1ac";
}

a.icn-mail::before,
button.icn-mail::before,
span.icn-mail::before,
p.icn-mail::before,
li.icn-mail::before {
    content: "\f0e0";
}

a.icn-download::before,
button.icn-download::before {
    content: "\f56d";
}

a.icn-upload::before,
button.icn-upload::before,
span.icn-upload::before {
    content: "\f093";
}

a.icn-pdf::before,
button.icn-pdf::before,
span.icn-pdf::before {
    content: "\f1c1";
}
a.icn-location::before,
button.icn-location::before,
span.icn-location::before,
li.icn-location::before {
    content: "\f3c5";
}

a.icn-linkedin::before,
button.icn-linkedin::before,
span.icn-linkedin::before {
    font-family: 'Font Awesome 5 Brands';
    content: "\f08c";
}

a.icn-loadMore::before,
button.icn-loadMore::before,
span.icn-loadMore::before,
a.icn-download::before,
button.icn-download::before,
span.icn-download::before {
    content: "\f381";
}
a.icn-pen::before,
button.icn-pen::before,
span.icn-pen::before {
    content: "\f304";
}

a.icn-trash::before,
button.icn-trash::before,
span.icn-trash::before {
    content: "\f1f8";
}

a.icn-times::before,
button.icn-times::before,
span.icn-times::before,
a.icn-cross::before,
button.icn-cross::before,
span.icn-cross::before {
    content: "\f00d";
}


// Show Hide fir Collapse Function
a[aria-expanded="false"] .show-hide::before {
    font-family: 'Font Awesome 5 Free';
    content: '\f06e';
    display: inline-block;
    font-size: 1.1em;
    line-height: 1em;
    margin-right: .25em;
    padding: 0;
    font-weight: 600;

}
a[aria-expanded="false"] .show-hide::after {
    content: 'Show ';
}
a[aria-expanded="true"] .show-hide::before {
    font-family: 'Font Awesome 5 Free';
    content: '\f070';
    display: inline-block;
    font-size: 1em;
    line-height: 1em;
    margin-right: .25em;
    padding: 0;
    font-weight: 600;
    backface-visibility: hidden;
    transform: translateZ(0);
    -webkit-font-smoothing: subpixel-antialiased;
}
a[aria-expanded="true"] .show-hide::after {
    content: 'Hide ';
}

html.non-touch a:hover .show-hide::before {
    transform-origin: 50% 50% 0;
    animation: pump 1s ease-in infinite;
}


html.non-touch a.icn-ani-pump:hover::before,
html.non-touch button.icn-ani-pump:hover::before,
html.non-touch .btn.icn-ani-pump:hover::before {
    transform-origin: 50% 50% 0;
    animation: pump 1s ease-in infinite;
}

html.non-touch a.icn-ani-bounceUp:hover::before,
html.non-touch button.icn-ani-bounceUp:hover::before {
    animation: bounce_up .5s ease-in-out infinite;
}

html.non-touch a.icn-ani-bounceDown:hover::before,
html.non-touch button.icn-ani-bounceDown:hover::before {
    animation: bounce_down .5s ease-in-out infinite;
}

html.non-touch a.icn-ani-bounceRight:hover::before,
html.non-touch button.icn-ani-bounceRight:hover::before,
html.non-touch span.icn-ani-bounceRight:hover::before {
    animation: bounce_right .5s ease-in-out infinite;
}

html.non-touch a.icn-ani-bounceLeft:hover::before,
html.non-touch button.icn-ani-bounceLeft:hover::before,
html.non-touch span.icn-ani-bounceLeft:hover::before {
    animation: bounce_left .5s ease-in-out infinite;
}


////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// 2.3.) Modals & Tooltips
////////////////////////////////////////////////////////////////////////////////////////////////////////////
.modal {
    .modal-content {
        border: solid 1px rgba($isc3-grey, 0.1);
        padding: 15px;
        .modal-header {
            padding: 1em;
            border-bottom: none;
            .close {
                color: $isc3-blue;
                font-size: 2.85em;
                padding-top: 0.2em;
                padding-bottom: 0.25em;
                position: absolute;
                top: 15px;
                right: 15px;
                opacity: 1;
            }
            .close:hover {
                color: $isc3-dark-blue !important;
            }
            .modal-title {
                text-align: center;
                width: 100%;
                i {
                    display: block;
                    font-size: 5em;
                    color: $isc3-blue;
                    width: 100%;
                    margin: 1rem 0 1rem 0;
                    text-align: center;
                }

                i.fa-user-check{
                    padding-left: 15px;
                }
            }
        }

        .modal-body {
            text-align: center;
            width: 100%;
            // padding: 0 .5em;
            ul {
                padding-left: 0;
                li {
                    line-height: 1.25em;
                    list-style-type: none;
                    margin-left: 0;
                    padding-left: 0;
                    text-indent: 0;
                    margin-bottom: 0.5em;
                    text-align: center;
                    a {
                        text-align: center;
                    }
                }
            }

            .col:last-of-type, [class*="col-"]:last-of-type {
                margin-bottom: 0;
            }

            input {
                width: 100%;
                // border: solid 1px $isc3-grey !important;
            }

        }

        .modal-footer {
            padding-left: 0;
            padding-right: 0;
            // margin-left: 1em;
            // margin-right: 1em;
            justify-content: center;
            border-top: none;
            text-align: center;
            width: 100%;

            a {
                font-size: 1em;
                letter-spacing: 0.025em !important;
                // justify-self: flex-start;
                font-weight: 400;
            }
        }
    }

    // prevent full With on mobile
    @media screen and (max-width:575px) {
        .modal-sm {
            margin-left: 15vw;
            margin-right: 15vw;
        }
    }

    .feedback {
        font-size: .85em;
        line-height: 1.25em;
        margin-bottom: 1em;
        color: $isc3-blue;
    }

}

// Modals
.modal.blue {
    .icn {
        display: inline-block;
        position: relative;

        width: 140px;
        height: 140px;
        background-image: url("../sass/img/icn_bg_circles_white.svg");
        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            width: 75px;
            height: auto;
        }
    }
    a {
        display: inline;
    }
    a.icn {
        width: auto !important;
        height: auto !important;
        background-image: none !important;
    }
    .btn-white {
        background-color: white !important;
        color: $isc3-blue !important;
    }


    .modal-content .modal-header .close {
        color: rgba(white, 0.75) !important;
        text-shadow: none;
        &:hover {
            color: rgba(white, 1) !important;
        }
    }

    .modal-content .modal-title  .icon-cont .icon {
        display: inline-block;
        width: 100px;
        height: 100px;
        border-radius: 0.25rem ;
        &::after {
            font-size: 3rem !important;
            line-height: 2;
        }
    }
    .modal-content {
        background-color: $isc3-blue;
        color: white !important;

        h2,
        h3,
        h4,
        h5,
        h6,
        p {
            color: white;
        }
    }


    li {
        margin-left: 1rem !important;
        line-height: 1.35 !important;

    }

    li::before {
        content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
        color: white; /* Change the color */
        font-weight: 600; /* If you want it to be bold */
        display: inline-block; /* Needed to add space between the bullet and the text */
        width: 1rem; /* Also needed for space (tweak if needed) */
        //margin-left: -1rem; /* Also needed for space (tweak if needed) */
        font-size: 150%;
        vertical-align: bottom;
        padding-bottom: 0.05rem;
        position: absolute;
        top: -0.35rem !important;
    }

    .section {
        margin-bottom: 1rem;
        &:last-child {
            margin-bottom: 0 !important;
        }
        &:empty {
            display: none !important;
        }
    }
    .tags {
        margin-bottom: 0.75rem;
        .tag {
            display: inline-block;
            border: solid 1px white;
            border-radius: 0.25rem;
            padding: 0 0.4rem;
            margin-bottom: 0.5rem;
            margin-right: 0.25rem;
        }
    }
    .modal-content .modal-footer:empty {
        display: none !important;
    }
}

// Hide tooltip on mobile
.tooltip {
    display: none;
}

html.non-touch .tooltip {
    display: block;
    line-height: 1.2 !important;
    .tooltip-inner, .tooltip-arrow {

        background-color: $isc3-darkest-blue !important;
    }
    .tooltip-inner {
        padding: 10px !important;
    }

}



////////////////////////////////////////////////////////////////////////////////////////////////////////////
////  Cursors
////////////////////////////////////////////////////////////////////////////////////////////////////////////
.cursor-nopointer {
    cursor: auto !important;
}

.cursor-pointer {
    cursor: pointer;
}



////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// 2.4.) Forms & Input
////////////////////////////////////////////////////////////////////////////////////////////////////////////

#btn-regInstitution,
#btn-regPerson,
#btn-regPhysEvent,
#btn-regOnlineEvent
    {
    width: 100%;
}

form {
    .col, [class*="col-"] {
        margin-bottom: 0 !important;
    }
    .input-group {
        margin-bottom: 1em !important;
        .iti, .custom-file, textarea ,label {
            display: inline-block;
            width: 100% !important;
        }
    }

    label {
        margin-bottom: .25rem;
    }

    input, textarea, select, .custom-file-label {
        background: none;
        border: solid 1px $isc3-light-grey !important;
        color: $isc3-dark-grey;
        border-radius: 0.25rem;
        outline: none;
        filter: none;
    }

    input, textarea, select {
        padding: 0 0.5em;
        min-height: 1.75em;
        font-weight: 300;
        letter-spacing: 0.05em;
        border-radius: 0.25rem;
    }

    // .form-control {
    //     line-height: 1.3;
    // }


    // Reset Form Radius
    .input-group.icn .form-control, .custom-file, input, textarea,
    input-group > .custom-file .custom-file-label,
    .input-group > .form-control,
    .input-group > .form-select,
    .input-group .custom-file-label::after,
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::before {
        border-radius: 0.25rem !important;
        border-top-right-radius:  0.25rem;
        border-bottom-right-radius:  0.25rem;
        border-top-left-radius:  0.25rem;
        border-bottom-left-radius:  0.25rem;
    }

    .input-group > .custom-file:nth-child(2n),
    .input-group > .form-control:nth-child(2n) {
        border-top-right-radius: 0.25rem !important;
        border-bottom-right-radius: 0.25rem !important;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }

    .input-group-prepend,
    .input-group > input[id^="__BVID__"]:not(:only-child) {
        border-top-right-radius: 0rem !important;
        border-bottom-right-radius: 0rem !important;
        border-bottom-left-radius: 0.25rem !important;
        border-top-left-radius: 0.25rem !important;
    }

    .input-group-append {
        border-top-right-radius: 0.25rem !important;
        border-bottom-right-radius: 0.25rem !important;
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 0 !important;
    }

    .input-group .custom-file-label::after {
        border-top-left-radius: 0rem !important;
        border-bottom-left-radius: 0rem !important;
    }

    img#preview {
        margin-top: 35px;
    }

    select.blue-select {
        width: 100%;
        font-weight: 400;
        appearance: none;
        border: none !important;
        border-radius: 5px;
        background: $isc3-blue;
        color: white;
        padding: 5px 40px 5px 10px;
        cursor: pointer;

        background-image: url("../sass/img/arrow_dwn.svg");
        background-repeat: no-repeat;
        background-size: 1.5em 1em;
        background-position: calc(100% - 10px) center;
        background-clip: border-box;

        option{
            width: calc(100% - 1.5em);
            word-break: break-all;
        }
    }
    select.blue-select:hover {
        transition: color .15s ease-in-out;
        background-color: .15s ease-in-out;
        box-shadow: .15s ease-in-out;
        box-shadow: $isc3-shadow-btn-hover;
    }

    select.blue-select:active {
        background-color: $isc3-dark-blue !important;
        box-shadow: $isc3-shadow-btn-active !important;
    }

    // select.icn-arrow-down::after {
    //     font-family: 'Font Awesome 5 Free';
    //     content: "\f078";
    //     margin-left: 0.25em;
    //     color: white;
    // }
    select.blue-select.icn-ani-bounceDown:hover i{
        animation: bounce_down .5s ease-in-out infinite;
    }
}

.croppa-container,
.croppa-container.croppa--has-target {
    background-color: #e6e6e6;
    width: 300px;
    height: 300px;

    // @media screen and (min-width:992px) {
    //     height: 425px !important;
    // }
    cursor: pointer;
    canvas {
        width: 100% !important;
        height: 100% !important;
    }
}

//Remove Focus Outline
input, textarea, select, .custom-file-label, .custom-checkbox,
input:focus, textarea:focus, select:focus, .custom-file-label:focus, .custom-checkbox:focus,
.custom-control-input:focus ~ .custom-control-label::before,
.form-check-input:focus, .form-check-input {
    outline: none;
    border: isc3-grey !important;
    box-shadow: none !important;
    filter: none;
}

.custom-control-input:checked ~ .custom-control-label::before, // Checkbox
.form-check-input:checked ~ .form-check-label::before // Radio
{
    background-color: $isc3-blue !important;
    color: white;
}

.custom-control-label::after, .custom-control-label::before {
    top: .35rem !important;
    }


.input-group.regGss-login {
    max-width: 450px;
}

textarea {
    margin-bottom: 0;
    @media screen and (min-width:992px) {
        max-height: 125px !important;
    }
}

.input-group-text {
    border: solid 1px $isc3-blue !important;
    background-color: $isc3-blue;
    color: white;

    i, span {
        color: white;
    }
}

input::placeholder,
textarea::placeholder,
.custom-file-label {
    color: $isc3-light-grey !important;
    font-weight: 200;
}

.custom-control-label,
.form-check-label,
.form-radio-label,
.custom-control-label {
    color: $isc3-dark-grey !important;
    font-weight: 300;
    cursor: pointer;
}


.custom-control-label::before
.custom-control-label::after {
    padding-top: 1em;
}

input.white::placeholder, textarea.white::placeholder,
select.white::placeholder {
    color: white !important;
}

input:focus, textarea:focus, select:focus {
    outline: none !important;
}
input.white {
    border-color: white !important;
    color: white;
}

img#preview {
    margin-top: 0.5em;
}

label {
    color: $isc3-blue !important;
    font-weight: 500;
}
.text-muted {
    color: rgba($isc3-light-grey, 0.75) !important;
    line-height: 1em;
    font-weight: 100;
}
select {
    font-weight: 300;
}

.form-check {
    padding-left: 0;
}

.form-check-inline  {
    margin-bottom: 0em;
}

.form-check-input,
.custom-control-input {
    line-height: .25em;
    margin-bottom: 0em;
}

.custom-file-input,
.custom-file-label {
    width: 100%;
    display: block;
    overflow-x: hidden;
    // cursor: pointer !important;
    // @media screen and (min-width:768px) {
    //     display: inline;
    //     width: 55%;
    // }
    // @media screen and (min-width:992px) {
    //     width: 65%;
    // }
    // @media screen and (min-width:1200px) {
    //     width: 70%;
    // }
}

#custom-file-browse {
    cursor: pointer !important;
    z-index: 2;
}
label#custom-file-browse::after {
    color: white !important;
    content: "browse" !important;
    background-color:  $isc3-blue !important;
    border: none !important;
    line-height: 1.5;
    pointer-events: none;
}

.btn.upload {
    margin-top: 0.5em;
    line-height: 1.6;
    user-select: none;
    width: 100%;
    display: block;
    float: none;
    @media screen and (min-width:992px) {
        width: auto;
        margin-top: 0;
    }
}

.form-text {
    margin-left: 0.5em;
    line-height: 1.25;
}


////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// 3.) CSS Animations Presets
////////////////////////////////////////////////////////////////////////////////////////////////////////////

@keyframes bounce_left {
    50% {transform: TranslateX(-5px)}
}

@keyframes bounce_right {
    50% {transform: TranslateX(5px)}
}
@keyframes bounce_up {
    50% {transform: TranslateY(-5px)}
}

@keyframes bounce_down {
    50% {transform: TranslateY(5px)}
}

@keyframes bounce_up_dwn {
    0% {transform: Translatey(0px)}
    25% {transform: Translatey(-3px)}
    50% {transform: Translatey(0px)}
    75% {transform: TranslateY(3px)}
    100% {transform: TranslateY(0px)}
}

@keyframes bounce_in_up {
    0% {
    opacity: 0;
    transform: translateY(500px);
    }
    60% {
    opacity: 1;
    transform: translateY(-10px);
    }
    75% {
    transform: translateY(5px);
    }
    90% {
    transform: translateY(-2px);
    }
    100% {
    transform: translateY(0);
    }
}

@keyframes bounce_in_dwn {
    0% {
    opacity: 0;
    transform: translateY(-500px);
    }
    60% {
    opacity: 1;
    transform: translateY(10px);
    }
    75% {
    transform: translateY(-5px);
    }
    90% {
    transform: translate3d(2px);
    }
    100% {
    transform: translate3d(0);
    }
}

@keyframes pump {
    0% {
    transform: scale(1);
    }
    50% {
    transform: scale(1.15);
    }
}

@keyframes appear {
    0% {
      transform: scale(2);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

  @keyframes disappear {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(2);
      opacity: 0;
    }
  }


////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// 4.) Footer
////////////////////////////////////////////////////////////////////////////////////////////////////////////



