
@import "mixins";




#article {
    width: 100%;

    &.filterable-content {
        padding: 0;
        // background: green;
        margin: 0 auto;
        max-width: 500px;

        // @media screen and (min-width:992px) {
        //     padding-right: 45px;
        // }
        // @media screen and (min-width:1200px) {
        //     padding-right: 75px;
        // }
        .content-wrapper {
            padding-bottom: 0;
            .artikel-header, #filter, #aktive-filter-container {
                // max-width: $max-width;
                max-width: $max-width;
                margin: 0 auto;
            }

            .artikel-header {
                padding: 0;
                background: transparent;

                .content-parts-filter {
                    padding: 30px 0 40px;
                    margin: 0 20px;
                    @include transition(margin);
                    background: $weiss;

                    &.termine {
                        h1 {
                            margin-bottom: 0;
                        }
                    }

                    p {
                        margin-bottom: 0;
                        font-weight: 600;
                        font-size: 1.5em;
                        padding: 0 10px;
                    }
                }
            }

            .content-parts-filter {
                max-width: $max-width;
                margin: 0 auto;
            }

            #filter {

                padding: 15px;
                width: 100%;
                max-width: 600px;
                background: rgba(white, 0.5);
                border-radius: 6px;

                @media screen and (min-width:768px) {
                    width: 100%;
                    max-width: 600px;
                }

                @media screen and (min-width:992px) {
                    
                }

                .filter-wrapper {
                    // margin-top: 10px;

                    .open-filter {
                        background: $dunkel-grau;
                        color: $weiss;
                        font-size: 1.125em;
                        @include signika(400);
                        height: 50px;
                        width: 100%;
                        @extend %display-flex;
                        @extend %align-items-center;
                        @extend %justify-content-space-between;
                        padding: 0 20px;
                        cursor: pointer;

                        .filter-icn {
                            position: relative;
                            @extend %display-flex;
                            @extend %align-items-center;
                            width: 20px;
                            height: 20px;

                            .line {
                                @extend %display-flex;
                                @include justify-content(center);
                                //display: block;
                                background: $weiss;
                                height: 1px;
                                width: 20px;
                                position: absolute;
                                @include transitionMultiple(transform .3s, background .3s);

                                &:after {
                                    content: '';
                                    width: 3px;
                                    height: 3px;
                                    background: $weiss;
                                    display: block;
                                    border-radius: 50%;
                                    border: solid 2px #333;
                                    top: -3px;
                                    position: absolute;
                                    @include transitionMultiple(transform .3s, opacity .3s);
                                }

                                &:before {
                                    content: '';
                                    width: 20px;
                                    height: 1px;
                                    background: $weiss;
                                    display: block;
                                    @include transitionMultiple(transform .3s, opacity .3s);
                                    opacity: 0;
                                    transition-delay: .3s, 0s;
                                    @include rotate(0);
                                }

                                &.one {
                                    @include translateY(-6px);

                                    &:after {
                                        @include translateX(2px);
                                        //transform: translateX(-50%) translateX(2px);
                                    }
                                }

                                &.two {
                                    &:after {
                                        @include translateX(-4px);
                                        //transform: translateX(-50%) translateX(-4px);
                                    }
                                }

                                &.three {
                                    @include translateY(6px);

                                    &:after {
                                        @include translateX(3px);
                                        //transform: translateX(-50%) translateX(3px);
                                    }
                                }

                                /* ###### IE11-Workaround ##### */

                                &.ie11 {
                                    &:after {
                                        left: 50%;
                                        transform: translateX(-50%);
                                        border-radius: 0;
                                    }

                                    &.one {
                                        &:after {
                                            transform: translateX(-50%) translateX(2px);
                                        }
                                    }

                                    &.two {
                                        &:after {
                                            transform: translateX(-50%) translateX(-4px);
                                        }
                                    }

                                    &.three {
                                        &:after {
                                            transform: translateX(-50%) translateX(3px);
                                        }
                                    }
                                }

                            }
                        }

                        &:hover {
                            .filter-icn .line {
                                &:after {
                                    @include translateX(0);
                                }
                                /* ###### IE11-Workaround ##### */
                                &.ie11 {
                                    &:after {
                                        transform: translateX(-50%) translateX(0);
                                    }
                                }

                            }
                        }
                        &.filter-open {
                            .line {
                                background: rgba(255,255,255,0);
                                transform: translate(0)!important;

                                &.one:before {
                                    @include rotate(45deg);
                                    transition-delay: .3s,0s;
                                    opacity: 1;
                                }
                                &.three:before {
                                    @include rotate(-45deg);
                                    transition-delay: .3s,0s;
                                    opacity: 1;
                                }

                                &:after {
                                    opacity: 0;
                                    @include translateX(0);
                                    transition-delay: 0s,.3s;
                                }
                            }
                        }
                    }

                    #filter-formular {
                        margin-top: 5px;
                        @extend %general-transition;
                        height: 0;
                        // overflow: hidden;
                        display: none;

                        .tab {
                            height: 50px;
                            width: 100%;
                            padding: 10px 13px 10px 10px;
                            font-weight: 400;
                            @extend %display-flex;
                            @extend %align-items-center;
                            @extend %justify-content-space-between;
                            @extend %general-transition;
                            background: $weiss;
                            border-color: $weiss;
                            cursor: pointer;
                            .tab-icn {
                                width: 7.6px;
                                height: 16px;
                                @include transition(transform);
                                svg {
                                    @include transition(fill);
                                    width: 7.6px;
                                    height: 16px;
                                    fill: $dunkel-grau;
                                }
                            }

                            &:hover {
                                color: $isc3-blue;
                                .tab-icn svg {
                                    fill: $isc3-blue;
                                }
                            }
                        }

                        fieldset {
                            @extend %general-transition;
                            border: none;
                            background: $weiss;
                            margin-bottom: 5px;
                            width: 100%;

                            &#barrierefreiheit {
                                margin-bottom: 20px;

                                .checkbox-wrapper {
                                    input {
                                        left: 10px;
                                    }
                                }
                            }
                        }

                        .drop-down-wrapper {
                            margin-bottom: 5px;

                            .tab {
                                margin-bottom: 5px;

                                &.tab-open {
                                    color: $isc3-blue;
                                    .tab-icn {
                                        @include rotate(90deg);

                                        svg {
                                            fill: $isc3-blue;
                                        }
                                    }
                                }
                            }


                            fieldset {
                                display: none;
                                margin-bottom: 5px;
                                margin-top: -4px;
                                height: 0;
                                overflow: hidden;

                                // .container-part {
                                //     padding: 20px 15px;
                                // }
                                input {
                                    @include signika(400);
                                    font-size: 1em;
                                }
                                hr {
                                    border-color: $hell-grau;
                                }

                                .checkbox-wrapper {
                                    input {
                                        left: 10px;
                                    }
                                }

                                .datepicker-btn-wrapper {
                                    @extend %display-flex;
                                    @extend %justify-content-space-between;
                                    @extend %flex-wrap-wrap;
                                    margin: 0 -3px -3px 0;

                                    .date-picker-btn {
                                        border: solid 1px $dunkel-grau;
                                        padding: 11px 10px;
                                        font-size: 1em;
                                        height: 46px;
                                        @include signika(400);
                                        flex-grow: 1;
                                        margin: 0 3px 3px 0;
                                        z-index: 1;
                                        position: relative;
                                        &#date-picker-wochenende {
                                            flex-grow: 2;
                                        }

                                        &:after {
                                            content: "";
                                            position: absolute;
                                            top: 0;
                                            bottom: 0;
                                            left: 0;
                                            width: 100%;
                                            background: $isc3-blue;
                                            z-index: -1;
                                            transform: scaleX(0);
                                            transition: transform 0.3s ease-in-out 0s;
                                            -webkit-transition: transform 0.3s ease-in-out 0s;
                                            -o-transition: transform 0.3s ease-in-out 0s;
                                        }

                                        &:hover {
                                            color: $weiss;
                                            border-color: $isc3-blue;
                                            &:after {
                                                transform: scaleX(1);
                                            }
                                        }

                                        &.active {
                                            background: $isc3-blue;
                                            color: $weiss;
                                            border-color: $isc3-blue;
                                        }
                                    }
                                }

                                .datepicker-input-container {

                                    .datepicker-input-wrapper {
                                        position: relative;
                                        margin-top: 10px;
                                        overflow: hidden;

                                        label {
                                            position: absolute;
                                            left: 10px;
                                            top: 12px;
                                        }

                                        .pointer {
                                            height: 0px;
                                            width: 0px;
                                            border-top: solid 5px transparent;
                                            border-bottom: solid 5px transparent;
                                            border-left: solid 5px #333;
                                            position: absolute;
                                            top: -10px;
                                            @extend %general-transition;
                                        }

                                        input {
                                            border: solid 1px $datepicker-border;
                                            width: 100%;
                                            height: 46px;
                                            padding: 11px 10px 11px 47px;
                                            @extend %general-transition;
                                            cursor: pointer;

                                            &:focus, &:hover {
                                                border-color: $dunkel-grau;
                                            }

                                            &:focus ~.pointer {
                                                top: 17px;
                                            }
                                        }

                                        &.error-occured {
                                            color: $rot;
                                            input {
                                                border-color: $rot;
                                                color: $rot;
                                            }
                                            .pointer {
                                                border-left-color: $rot;
                                            }
                                        }


                                    }
                                }

                                .datepicker-wrapper {
                                    .datepicker-header {
                                        @extend %display-flex;
                                        @extend %justify-content-space-between;
                                        font-size: 1.25em;
                                        font-weight: 700;
                                        background: $isc3-blue;
                                        color: white;
                                        padding: 10px 20px;
                                        .arrows {
                                            width: 16px;
                                            height: 26px;
                                            cursor: pointer;
                                            .icn::before {
                                                font-size: 1.5rem;
                                            }
                                            // &:hover {
                                            //     svg {
                                            //         fill: $isc3-blue;
                                            //     }
                                            // }
                                        }
                                    }

                                    .datepicker-body {
                                        margin: 20px;

                                        .datepicker-row {
                                            width: 100%;
                                            @extend %display-flex;
                                            @extend %justify-content-space-between;

                                            .label {
                                                width: 30px;
                                                height: 30px;
                                                line-height: 30px;
                                                text-align: center;
                                                font-weight: 400;
                                                margin: 4px 2px;
                                                border-radius: 10%;
                                                @extend %general-transition;
                                                cursor: pointer;

                                                &:hover {
                                                    background: $isc3-blue;
                                                    color: $weiss;
                                                }

                                                &.datepicker-week {
                                                    font-weight: 600;
                                                    pointer-events: none;
                                                }

                                                &[class*='past'] {
                                                    color: $datepicker-past;
                                                    cursor: pointer;
                                                }

                                                &[class*='next-month'] {
                                                    color: $datepicker-next;
                                                    cursor: pointer;
                                                }

                                                &.current {
                                                    outline: solid 2px $isc3-blue;
                                                    outline-offset: -2px;
                                                    // background: $isc3-blue-alpha-050;
                                                }

                                                &.begin, &.end {
                                                    background: $isc3-blue;
                                                    color: $weiss;
                                                }

                                                &.between {
                                                    background: $isc3-blue-alpha-050;
                                                    color: $weiss;
                                                }
                                            }
                                        }
                                    }
                                }

                            }
                        }
                    }

                    .reset-filter-container {
                        text-align: center;
                        margin-top: 5px;

                        #reset-filter {
                            padding: 10px;
                            font-size: .875em;
                            @include signika(400);

                            img, svg {
                                width: 8px;
                                height: 11.703px;
                                margin-right: 10px;
                                @include transitionMultiple(transform .4s, fill .4s);
                            }

                            &:hover {
                                img, svg {
                                    transform: rotate(360deg);
                                    fill: $rot;
                                }
                            }
                        }
                    }
                }
            }

            #aktive-filter-container {
                background: $hell-grau;
                padding: 20px;
                display: none;
                margin-top: 1px;

                &.show {
                    display: block;
                }

                .container-aktive-filter {
                    margin: 0 -2px -4px;

                    .text {
                        margin: 0 8px 0 2px;
                    }

                    .aktiver-filter {
                        display: inline-block;
                        padding: 5px 10px;
                        border: solid 1px $dunkel-grau;
                        opacity: .5;
                        font-weight: 400;
                        font-size: 1em;
                        position: relative;
                        margin: 0 2px 4px;
                        cursor: pointer;
                        @include display-inline-flex;
                        @extend %align-items-center;
                        @include transition(opacity, .3s);

                        &:hover {
                            opacity: 1;
                            color: inherit;
                        }

                        .close-icn {
                            position: relative;
                            width: 15px;
                            height: 15px;
                            margin-left: 10px;
                            @include display-inline-flex;
                            @extend %align-items-center;

                            .line {
                                width: 100%;
                                height: 1px;
                                position: absolute;
                                background: $dunkel-grau;

                                &.one {
                                    @include rotate(-45deg);
                                }

                                &.two {
                                    @include rotate(45deg);
                                }
                            }
                        }
                    }
                }

            }

            #highlights, #termine,#nichts-gefunden {
                .button {
                    margin-top: 60px;
                }
            }

            #nichts-gefunden {
                .button {
                    padding: 15px 35px;
                }

                /*&.padding {
                    padding-top: 0;
                }*/
            }

            // #termine {
            //     //	background: $hell-gold;
            // }

            div.filter-result {
                section {
                    &:first-of-type {
                        .alle-termine {
                            article {
                                .empty {
                                    background: #fff!important;
                                }
                            }
                        }
                    }

                    &:nth-of-type(2) {
                        background: $hell-gold;
                    }

                    /*	&:last-of-type {
                            background: $weiss;
                        } */

                    &:nth-of-type(3) {
                        &#nichts-gefunden {
                            padding: 60px 20px;
                        }
                    }

                }
            }
        }

    }

}
