@charset "UTF-8";
////////////////////////////////////////////////////////////////////////////////////////////////////////////
// ISC3.org variables.scss
// Include of style.scss
// Author: Nils Grassmann
// Copyright: Style & Grace – Design & Advertising (2021)
////////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// Table of Contents
////////////////////////////////////////////////////////////////////////////////////////////////////////////

// 1.) MAIN VARIABLES
//     1.1) COLOR VARIABLES
//     1.2) SHADOWS

// 2.) NAV VARIABLES
//


// Viewports //
$mobile: 0px; // = mob
$mobile-large: 576px; // = mob-l
$tablet: 768px; // = tab
$desktop: 992px; // = dsk
$desktop-large: 1200px; // dsk-l



////////////////////////////////////////////////////////////////////////////////////////////////////////////
// 1.) MAIN VARIABLES
////////////////////////////////////////////////////////////////////////////////////////////////////////////

//// 1.1) COLOR VARIABLES /////
//$isc3-blue: rgba(0, 164, 220);
$isc3-blue: rgba(0, 164, 217); // nach Corporate Design
$isc3-blue-alpha-025: rgba(0, 164, 217,.25);
$isc3-blue-alpha-050: rgba(0, 164, 217,.50);
$isc3-blue-alpha-075: rgba(0, 164, 217,.75);
$isc3-dark-blue: rgba(0, 135, 185);
$isc3-darker-blue: rgba(0, 33, 58);
// $isc3-darker-blue: rgba(28, 65, 66); // nach Corporate Design
$isc3-darkest-blue: rgba(0, 20, 35);

$isc3-dark-grey: rgba(28, 56, 66); // nach Corporate Design
$isc3-grey: rgba(120, 135, 145);
$isc3-light-grey: rgba(150, 168, 180, 1);
$isc3-lighter-grey: rgba(150, 168, 180, 0.075);

$isc3-green: rgba(99, 207, 69); // nach Corporate Design
$isc3-dark-green: rgba(87, 179, 59); // nach Corporate Design

$isc3-red: rgba(255,0,0);



//// 1.2) Shadows /////
//for boxed elements
$isc3-box-shadow: 0 4px 4px rgba($isc3-darkest-blue, 0.075);
$isc3-box-shadow-large: 0 0px 15px rgba($isc3-darkest-blue, 0.35);
//for btns
$isc3-shadow-btn-hover: 0px 2px 2px rgba($isc3-darkest-blue, 0.25);
$isc3-shadow-btn-active: inset 0px 6px 6px rgba($isc3-darkest-blue, 0.5);

//// 1.2.) NAV VARIABLES//
$nav-height-mob: 90px;
$nav-height-dsk: 100px;
////////////////////////////////////////////////////////////////////////////////////////////////////////////


@mixin btn-white {
    .btn,
    html.non-touch .btn:hover,
    .btn:active {
        color: $isc3-blue !important;
        background-color: white !important;
    }

    .btn-outline,
    html.non-touch .btn-outline:hover {
        color: white !important;
        border: 2px solid white;
        background: none;
    }
}

@mixin btn-blue {
    .btn,
    html.non-touch .btn:hover,
    .btn:active {
        color:white !important;
        background-color: $isc3-blue !important;
    }

    .btn-outline,
    html.non-touch .btn-outline:hover {
        color: $isc3-blue  !important;
        border: 2px solid $isc3-blue ;
        background: none;
    }
}

@mixin text-dark {
    color: $isc3-darker-blue !important;
    h1, h2, h3, h4, h5, h6 {
        color: $isc3-blue !important;
    }
    @include btn-blue;
}

@mixin text-white {
    color: white !important;
    h1, h2, h3, h4, h5, h6 {
        color: white !important;
    }
    @include btn-white;
}



@mixin bg-blue {
    background-color: $isc3-blue;
    @include text-white;
    @include btn-white;
    li::before {
        color: white !important; /* Change the color */
    }
}

@mixin bg-white {
    background-color: white;
    @include text-white;
    @include btn-white;
    li::before {
        color: white !important; /* Change the color */
    }
}

@mixin bg-green {
    background-color: $isc3-green;
    @include text-white;
    @include btn-white;
    li::before {
        color: white !important; /* Change the color */
    }
}

// @mixin bg-white {
//     background-color: white;
//     // @include text-blue;
//     // @include btn-blue;
//     li::before {
//         color: $isc3-blue !important; /* Change the color */
//     }
// }
