$gold: #cc9b38;
$gold-alpha-1: rgba(204,155,56,1);
$gold-alpha-0: rgba(204,155,56,0);
$gold-alpha-075: rgba(204,155,56,.75);
$gold-alpha-60: rgba(204,155,56,.6);
$dunkel-grau: #333;
$weiss-alpha-075: rgba(255,255,255,.75);
$weiss: #fff;
$rot: #C64849;
$hell-gold: #FAF5EA;
$hell-grau: #F6F3F0;
$figcaption: #f0f0f0;
$blau: #00509B;

$datepicker-past: #d6d6d6;
$datepicker-next: #666;
$datepicker-border: #989898;
$input-border: #989898;

$spk-rot: #ff0000;
$diakonie-lila: #7E4A94;
$swh-blau: #0078B9;

$custom-ease-in: cubic-bezier(.59,.01,.53,.99);

$tablet: 768px;
$desktop: 1024px;
$desktop-large: 1300px;

$small-height: 820px;

$minSize-h2: 30px;
$maxSize-h2: 45px;

$minExcerpt: 21px;
$maxExcerpt: 24px;

$minAuthor: 14px;
$maxAuthor: 16px;

$max-width: 720px;




@function str-replace($string, $search, $replace: '') {
    $index: str-index($string, $search);

    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }

    @return $string;
}

@mixin transition($attribute:all,$time:.4s,$easing:ease-in-out,$delay:0s) {
    transition: $attribute $time $easing $delay;
    -webkit-transition: $attribute $time $easing $delay;
    -o-transition: $attribute $time $easing $delay;
}

@mixin transitionMultiple($attr...) {
    transition: $attr;
    -webkit-transition: $attr;
    -o-transition: $attr;
    -webkit-transition-timing-function: ease-in-out;
    -o-transition-timing-function: ease-in-out;
    transition-timing-function: ease-in-out;
}

@mixin signika($font-weight) {
    font-family: Signika, Helvetica, sans-serif;
    font-weight: $font-weight;
}

@mixin roboto-slab {
    font-family: 'Roboto Slab', serif;
}

@mixin font-weight($weight) {
    font-weight: $weight;
}

@mixin translateY($val) {
    -webkit-transform: translateY($val);
    -ms-transform: translateY($val);
    transform: translateY($val);
}

@mixin translateX($val) {
    -webkit-transform: translateX($val);
    -ms-transform: translateX($val);
    transform: translateX($val);
}

@mixin flex-shrink($val:0) {
    -ms-flex-negative: $val;flex-shrink: $val;
}

@mixin flex-basis($val) {
    -ms-flex-preferred-size: $val;flex-basis: $val;
}

@mixin translateBoth($valX,$valY) {
    -webkit-transform: translate($valX,$valY);
    -ms-transform: translate($valX,$valY);
    transform: translate($valX,$valY);
}

@mixin scale($val,$axis:xy) {

    @if $axis == 'xy' {
        -webkit-transform: scale($val);-ms-transform: scale($val);transform: scale($val);
    } @else {
        -webkit-transform: scale + $axis + unquote('(') + $val + unquote(')');-ms-transform: scale + $axis + unquote('(') + $val + unquote(')');transform: scale + $axis + unquote('(') + $val + unquote(')');
    }
}

@mixin rotate($val) {
    -webkit-transform: rotate($val);-ms-transform: rotate($val);transform: rotate($val);
}

@mixin flex-grow($val) {
    -webkit-box-flex: $val;
    -ms-flex-positive: $val;
    flex-grow: $val;
}

@mixin transition-delay($total-childs, $delay) {
    @for $i from 1 through $total-childs {
        &:nth-child(#{$i}) {
            transition-delay: #{$i * $delay};
        }
    }
}

@mixin media($type, $bpWidth, $operator) {

    @if $type == 'w' {

        @if $operator == 'min' {
            @media only screen and (min-width: $bpWidth) {
                @content;
            }
        }

        @if $operator == 'max' {
            @media only screen and (max-width: $bpWidth) {
                @content;
            }
        }
    }

    @if $type == 'h' {

        @if $operator == 'min' {
            @media only screen and (min-height: $bpWidth) {
                @content;
            }
        }

        @if $operator == 'max' {
            @media only screen and (max-height: $bpWidth) {
                @content;
            }
        }
    }
}

@mixin media-w-h($operatorW,$w,$operatorH,$h) {
    @media only screen and ($operatorW: $w) and ($operatorH: $h) {
        @content;
    }
}

@mixin display-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

@mixin display-inline-flex {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

@mixin flex($val) {
    -webkit-box-flex: $val;
    -ms-flex: $val;
    flex: $val;
}

@mixin align-items($val) {

    @if $val == 'flex-start' {
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    } @else if $val == 'flex-end' {
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;
    } @else {
        -webkit-box-align: $val;
        -ms-flex-align: $val;
        align-items: $val;
    }
}

@mixin justify-content($val) {
    @if $val == 'flex-end'{
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
    } @else if $val == 'flex-start'{
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    } @else if $val == 'space-between' {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    } @else if $val == 'space-around' {
        -ms-flex-pack: distribute;
        justify-content: space-around;
    } @else {
        -webkit-box-pack: $val;
        -ms-flex-pack: $val;
        justify-content: $val;
    }
}

@mixin flex-wrap($wrap:wrap) {
    -ms-flex-wrap: $wrap;
    flex-wrap: $wrap;
}

@mixin backdrop-filter($blur) {
    -webkit-backdrop-filter: blur($blur);
    backdrop-filter: blur($blur);
}

@mixin align-self($val) {

    @if $val == 'center' or $val == 'stretch' {

        -ms-flex-item-align: $val;
        -ms-grid-row-align: $val;
        align-self: $val;

    }

    @if $val == 'flex-start' or $val == 'flex-endx' {
        -ms-flex-item-align: str-replace($val, 'flex-', '');
        align-self: $val;
    }
}

@mixin flex-direction {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
}

@function strip-unit($value) {
    @return $value / ($value * 0 + 1);
}

@mixin fluid-type-size($minSize, $maxSize) {
    font-size: calc(#{$minSize} + #{strip-unit($maxSize - $minSize)} * ((100vw - #{$tablet}) / (1600 - #{strip-unit($tablet)})));
}

@mixin line-clamp($val:3) {
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: $val;
    -webkit-box-orient: vertical;
}

@mixin border($val:all) {
    @if $val == 'all' {
        border: solid 1px $gold;
    } @else if $val == 'top' {
        border-top: solid 1px $gold;
    } @else if $val == 'bottom' {
        border-bottom: solid 1px $gold;
    } @else if $val == 'left' {
        border-left: solid 1px $gold;
    } @else if $val == 'right' {
        border-right: solid 1px $gold;
    }
}

@mixin placeholder($color) {
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $color;
        opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: $color;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
        color: $color;
    }

}

@mixin flex-shrink($val) {
    -ms-flex-negative: $val;flex-shrink: $val;
}

%roboto-slab {
    font-family: 'Roboto Slab', serif;
}

%font-weight-light {
    font-weight: 300;
}

%font-weight-regular {
    font-weight: 400;
}

%font-weight-semibold {
    font-weight: 600;
}

%font-weight-bold {
    font-weight: 700;
}

%prime-color {
    color: $gold;
}

%prime-fill {
    fill: $gold;
}

%gray-color {
    color: $dunkel-grau;
}

%gray-fill {
    fill: $dunkel-grau;
}

%position-absolute {
    position: absolute;
}

%position-relative {
    position: relative;
}

%general-transition {
    @include transition;
}

%display-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

%align-items-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

%flex-wrap-wrap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

%justify-content-flex-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

%justify-content-space-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

%flex-direction-row-reverse {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
}

%appearance-none {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: none;
    border: none;
}

%cursor-pointer {
    cursor: pointer;
}

%center-horizontal {
    left: 50%;
    @include translateX(-50%);
}

