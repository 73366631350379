@charset "UTF-8";
////////////////////////////////////////////////////////////////////////////////////////////////////////////
// ISC3.org _components.scss
// Include of style.scss
// Author: Nils Grassmann
// Copyright: Style & Grace – Design & Advertising (2021)
////////////////////////////////////////////////////////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// Table of Contents
////////////////////////////////////////////////////////////////////////////////////////////////////////////

// 1.) MAIN SITE COMPONENTS (Components found on every Site)

// 2.) COMPONENTS
//
// 3.) ADDITIONAL CLASSES
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// 1.) MAIN SITE COMPONENTS
////////////////////////////////////////////////////////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// 2.) COMPONENTS
////////////////////////////////////////////////////////////////////////////////////////////////////////////

//   3.1.) Hero
// Hero Main Settings
#hero {
    // border-bottom: solid 6px $isc3-blue;
    overflow: hidden;
    position: relative;

    // IE Fix
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        background-color: $isc3-blue;
    }
}

// Hero with Image
#hero.img-hero  {
    .img-cont {
        overflow: hidden;
        height: 50vh;
        max-height: 740px;
        z-index: 0;
        position: relative;

        @media screen and (min-width:1200px) {
            height: 60vh;
        }

        // Hero Image Element
        .img-el {
            position: absolute;
            width: 100.2%;
            height: 100.2%;
            overflow: hidden;
            display: none; // first of Type -> display: block

            // image
            img, video {
                position: absolute;
                min-width: 100.2%;
                min-height: 100.2%;
            }

            // Hero Image quote
            .img-quote {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                padding: 2em 30px;
                background-color: rgba($isc3-darker-blue, 0.85);
                background-blend-mode: multiply;
                color: white;

                @media screen and (min-width:768px) {
                    top: 50%;
                    bottom: auto;
                    padding: 2em 5vw;
                    transform: translateY(-50%);
                    max-width: calc(50% - 40px);
                }
            }

            // Image Quote Triangle
            .img-quote::after {
                content: ' ';
                display: block;
                width: 18px;
                height: 36px;
                position: absolute;
                top: 0;
                left: 50%;
                transform-origin: center bottom;
                transform: rotate(-90deg) translate(45px, 18px);
                background: no-repeat center center url("img/triangle_heroquote.svg");
                // background-color: pink;
                opacity: 0.85;
                background-blend-mode: multiply;


                @media screen and (min-width:768px) {
                    left: 100%;
                    top: 50%;
                    transform-origin: center center;
                    transform: translate(0, -50%);
                    z-index: 10;
                }
            }
        }

        .img-el:first-of-type {
            display: block; // others -> display: none
        }

        // img-el with centered image
        .img-el.img-align-center {
            img {
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        // img-el with image aligned right
        .img-el.img-align-right,
        .img-el.ing-align-right {
            img {
                top: 50%;
                right: 0;
                left: auto;
                transform: translate(15%, -50%);

                @media screen and (min-width:768px) {
                    transform: translate(0, -50%) !important;
                }
            }
        }

        // img-el with image aligned left
        .img-el.img-align-left {
            img {
                top: 50%;
                left: 0;
                right: auto;
                transform: translate(-15%, -50%);

                @media screen and (min-width:768px) {
                    transform: translate(0, -50%);
                }
            }
            @media screen and (min-width:768px) {
                .img-quote {
                    right: 0;
                    left: auto;
                }
                .img-quote::after {
                    right: 100%;
                    left: auto;
                    top: 50%;
                    transform-origin: center center;
                    transform: rotate(180deg) translate(0, 18px);
                }
            }
        }
    }
}

#hero.img-hero.spotlights-hero  {
    .img-cont {
        // img-el with image aligned right
        .img-el.img-align-right,
        .img-el.ing-align-right {
            img {
                top: 50%;
                right: 0;
                left: auto;
                transform: translate(19%, -50%);


                @media screen and (min-width:768px) {
                    transform: translate(0, -50%) !important;
                }
            }
        }
    }
}

#hero.img-hero.spotlights-hero.responsive-hero {
    .img-cont {
        // img-el with image aligned right
        .img-el {

            img {
                top: 50%;
                right: 0;
                // left: auto;


                    content: url('https://www.isc3.org/cms/wp-content/uploads/2024/09/SC-Spotlight2024_Hero_mob2.jpg') !important;

            }

            @media screen and (min-width:$desktop-large) {
                img {
                    top: 50%;
                    right: 0;
                    // left: auto;

                    content: url('https://www.isc3.org/cms/wp-content/uploads/2024/09/spotlight2024_hero_dskt2.jpg') !important;

                }
            }

        }
    }
}

// Hero with Image Scaleable
#hero.img-hero.scalable {
    // @media screen and (min-width:768px) {
    // }
    background: $isc3-darker-blue !important;
    .img-cont {
        top: -8px;
        height: calc(100vw / 2.45) !important;
        @media screen and (min-width:1200px) {
            max-height: 60vh !important;
        }
        .img-el {
            // top: -2px !important;
            img, video {
                width: 100.5vw !important;
                min-width: 0 !important;
                min-height: auto;
            }
        }

        .img-el.img-align-center,
        .img-el.img-align-right,
        .img-el.img-align-left {
            img {
                top: 0;
                left: 0;
                right: 0;
                transform: none;

                @media screen and (min-width:1200px) {
                    top: 50%;
                    transform: translateY(-50%);
                }

            }
        }

    }
}

#hero.video-hero {
    .video-cont {
        position: relative;
        background-color: $isc3-darker-blue;
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: fit-content;
        .video-cntrl {
            position: absolute;
            bottom: 10px;
            right: 10px;
            width: fit-content;
            height: auto;
            z-index: 2;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
        .icn{
            position: relative;
            margin: 2px;
            cursor: pointer;
            display: inline;
            background-image: url("../sass/img/icn_bg_circles_white.svg");
            width: 40px;
            height: 40px;
            &::before {
                font-family: 'Font Awesome 5 Free';
                content: "\f028";
                font-weight: 900;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                font-size: 1em;
                color: $isc3-blue;
            }

            &.active {
                &::before {
                    content: "\f6a9";
                }
            }
        }
        .btn-scrub-bkw {
            &::before {
                content: "\f0e2";
            }
            display: none;
            @media screen and (min-width:768px) {
                display: inline;
            }
        }
        .btn-scrub-fwd{
            &::before {
                content: "\f01e";
            }
            display: none;
            @media screen and (min-width:768px) {
                display: inline;
            }
        }
        .btn-fullscreen::before {
            content: "\f065";
        }
        .btn-volume-toggle.big {
            width: 80px;
            height: 80px;
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            z-index: 2;
            &::before {
                font-family: 'Font Awesome 5 Free';
                font-size: 2em;
            }
            @media screen and (min-width:768px){
                width: 120px;
                height: 120px;
                &::before {
                    font-family: 'Font Awesome 5 Free';
                    font-size: 2.5em;
                }
            }
        }
        .btn-play-toggle {
            &::before {
            content: "\f04c";
            }
            &.active::before {
                content: "\f04b";
            }
        }


        img, video {
            position: relative;
            top:0;
            bottom:0;
            left: 0;
            width: 100vw;
            height: auto;
            max-height: calc(100vh - 250px);
        }

    }

}

// Hero-txt Field
.hero-txt {
    position: absolute;
    bottom: 0px;
    padding: 15px;
    background-color: rgba($isc3-darker-blue, 0.85);
    background-blend-mode: normal;
    z-index: 2;

    border-bottom: solid 6px $isc3-blue;
    padding: 30px 15px;
    @media screen and (min-width:992px) {
        min-height: 200px;
    }

    @media screen and (min-width:768px) {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        max-width: 65vw;
        // min-width: 30vw;
        width: 57.5%;
        padding: 2em 5vw;
        background-color: rgba($isc3-darker-blue, 0.85);
        background-blend-mode: multiply;
    }

    .hero-icn {
        display: block;
        color: $isc3-blue;
        font-size: 500%;
        margin-bottom: 1em;
    }
}

// Hero Text alternative with full with on Bottom
.hero-txt.txt-btm-fullwidth {
    width: 100%;
    max-width: 100%;
    position: relative;
    transform: none;
    background-color: rgba($isc3-darker-blue, 1);
}

// Simple Text Hero
#hero.txt-hero {
    width: 100%;
    background-color: rgba($isc3-darker-blue, 1);
    border-bottom: solid 6px $isc3-blue;

    .hero-txt {
        position: relative;
        padding: 4em 0;
        transform: translateY(0);
        border-bottom: none;
        @media screen and (min-width:768px) {
            width: 75% !important;
        }
    }
}

#hero.hero-visual {
    border-bottom: solid 6px $isc3-darker-blue;
    .img-cont {
        max-height: 740px;
    }
    .hero-txt {
        h1 {
            color: rgba($isc3-dark-grey, 1) !important;
        }
        // h2, h3,
        // .h2, .h3 {
        // font-family: 'Etelka Pro Bold','Ubuntu', sans-serif !important;
        // display: block;
        // font-weight: 600;
        // font-size: 3em;
        // line-height: 2.5em;
        // }
        position: absolute;
        top: 85%;
        transform: translateY(-85%);
        height: fit-content;
        background: none;
        border-bottom: none;
    }
}

// 3.2) Online Atlas & Event Calendar
.hero-txt-atlas,
.hero-txt-event {
    position: relative;
    padding-top: 1em !important;
    padding-bottom: 1em !important;
    border-bottom: solid 2px $isc3-blue;
    margin-bottom: 0;
    bottom: 0;
    min-height: 0;
    h2 {
        margin-bottom: 0!important;
    }
}

.atlas-hero,
.event-hero {
    height: 65vh;
    background-color: $isc3-darker-blue !important;
    border-bottom: solid 6px $isc3-blue;

    #atlas-anchor,
    #event-anchor {
        position: relative;
        top:  - $nav-height-mob;
        @media screen and (min-width:768px) {
            top:  - $nav-height-dsk;
        }
    }
    #atlas-info,
    #event-info {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 4;
        height: 100%;

        margin-left: -40px; //inset left
        width: 640px; // + 40px for inset left for slide Animation
        max-width: calc(100vw + 40px); // + 40px for inset left for slide Animation

        background-color: rgba($isc3-darker-blue, 0.95);
        background-blend-mode: multiply;
        overflow-y: hidden !important;
        overflow-x: hidden !important;
        visibility: hidden;

        .btn.atlas-info-close,
        .btn.event-info-close {
            position: absolute;
            right: 10px;
            top: 10px;
            color: $isc3-blue;
            font-size: 4em;
            line-height: 0.5em;
            padding: 0 0 0.125em 0;
            z-index: 3;
            border-radius: 0;
            box-shadow: none;
            background: none;
        }

        .atlas-info-cont,
        .event-info-cont {
            padding: 1.25rem 15px 1.25rem 55px;

            @media screen and (min-width:768px) {
                padding: 1.25rem 35px 1.25rem 75px;
            }
            height: auto;
            max-height: 100%;
            margin-bottom: auto;
            margin-top: auto;
            overflow-y: auto;

            .atlas-info-details,
            .atlas-info-contact,
            .atlas-info-links,
            .event-info-details,
            .event-info-contact,
            .event-info-location,
            .event-info-links {
                margin-bottom: 0.5em;
                overflow-x: hidden;
            }

            .event-info-links {
                a {
                    display: inline-block;
                    margin-right: 0.5em;
                }
                a:last-of-type {
                    margin-right: 0;
                }
            }

            .atlas-info-logo {
                width: 90px;
                height: 90px;
                padding: 10px;
                border-radius: 5px;
                background-color: white;
                box-shadow: $isc3-box-shadow;
                margin-bottom: 1em;

                img {
                    width: 100%;
                    height: auto;
                    object-fit: contain;
                    position: relative;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

            .atlas-info-sm,
            .event-info-sm {
                font-size: 1.75em;
                a {
                    margin-right: 0.5em;
                }
                a:last-of-type {
                    margin-right: 0;
                }
            }
        }
    }
    #event-info {
        .event-result-properties {
            margin-top: 1em;
            margin-bottom: 0.5em;
        }
        width: calc(100vw + 40px); // + 40px for inset left for slide Animation
        max-width: none;
        @media screen and (min-width:768px) {
            width: calc(50vw + 40px); // + 40px for inset left for slide Animation
            max-width: none;
        }
    }

    .img-cont {
        height: 100% !important;
        max-height: none !important;
    }
}

.atlas-hero {
    #map {
        circle {
            height: 100px !important;
            width: 100px !important;
        }
    }

    .atlas-flag {
        padding: 10px;
        background-color: white;
        border-radius: 5px;
        min-width: 150px;
        max-width: 250px;
        .atlas-flag-logo {
            text-align: center;
            width: 100%;
            height: 65px;
            margin-bottom: 5px;
            img {
                display: inline-block;
                height: 65px !important;
                width: auto;
                object-fit: contain;
            }
        }
    }

    // hide orig. buttons,
    // hoide orig. legend
    g [role="button"],
    #legend {
        display: none;
        position: absolute;
        top: 50px;
        right: 50px;
    }

    // Map Logo links unten entfernen
    g[aria-labelledby="id-82-title"] {
        // fill: pink !important;
        // background-color: aquamarine;
        display: none;
    }

    // Country and City Flags
    g [role="tooltip"] {
        stroke: white;
        filter: drop-shadow(0 6px 3px rgba(0, 20, 35, 0.2));
        g {
            fill: $isc3-blue !important;
            fill-opacity: 1;
            stroke: $isc3-blue;
            stroke-opacity: 1;
            filter: none;
            path {
                fill: white  !important;
                stroke: white;
            }
        }
    }

    #map {
        height: 100% !important;
    }

    .atlas-tooltip {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        padding: 1em 30px;
        background-color: rgba($isc3-darker-blue, 0.85);
        background-blend-mode: multiply;
        color: white;
        z-index: 2;
        text-align: center;
        line-height: 1.5em;
        display: none;

        p:empty {
            display: none;
        }
    }
    // Atlas Tooltip Triangle
    .atlas-tooltip::after {
        content: ' ';
        display: block;
        width: 18px;
        height: 36px;
        position: absolute;
        top: 100%;
        left: 50%;
        transform-origin: center bottom;
        transform: rotate(90deg) translate(-27px, 18px);
        background: no-repeat center center url("img/triangle_heroquote.svg");
        opacity: 0.85;
        background-blend-mode: multiply;
    }

    .atlas-btn-nav {
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 3;
        padding: 10px 5px 10px 15px;
        @media screen and (min-width:768px) {
            right: 15px;
            bottom: 15px;
        }
        .btn {
            font-size: 1.5em;
            line-height: 1em;
            display: block;
            width: 40px;
            height: 40px;
            margin: 6px;
            padding: 0.25em;
        }
    }
}

.atlas-result-logo,
.event-result-date,
.event-info-date {
    position: relative;
    width: 100px !important;
    height: 100px !important;
    border-radius: 5px;
    box-shadow: $isc3-box-shadow;
    margin-bottom: 1em;
    text-align: center;
    padding: 15px;
    background-color: white;
    flex-shrink: 0;

    img {
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 70px;
        height: auto;
    }
}
.event-result-date,
.event-info-date {
    background-color: $isc3-blue;
    padding: 0;
    overflow: hidden;
    position: relative;

    .date-cont {
        height: 200px;
        width: 100px;
        font-size: 1.3rem;
        font-weight: 200;
        line-height: 1.25em;
        color: white;
        position: absolute;
        top: 0px;

        outline: 5px $isc3-blue solid;
        // outline-offset: -5px;
        // border-radius: 5px;


        .date-cont-start,
        .date-cont-end {
            width: 100px;
            height: 100px;
            text-align: center;
            padding-top: 5px;
        }
        .start-end {
            background: white;
            color: $isc3-blue;
            font-size: 0.8em;
            font-weight: 500;
            letter-spacing: 0.075em;
            line-height: 1.5em;
            padding-top: 0.05em;
            // margin-top: 5px;
            margin-left: 5px;
            margin-right: 5px;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
        }
        .day {
            display: inline-block;
            padding-top: 5px;
            padding-left: 15px;
            margin: 0;
        }

        .month {
            display: inline-block;
            padding-right: 15px;
            margin: 0;
            padding-bottom: 0.05em;
        }

        .year {
            font-size: 1.25em;
            font-weight: 900;
            padding-top: 0.05em;
            margin-left: 15px;
            margin-right: 15px;
            border-top: solid 1px white;
        }
    }

    .date-cont-singleday {
        .start-end {
            margin-top: 5px;
        }
    }
}

.event-result-properties,
.atlas-result-properties {
    div {
        display: inline !important;
    }
    div::before {
        content: "| ";
        display: inline;
    }
    div:first-of-type::before {
        content: none;
    }
    margin-top: 0;
    margin-bottom: 0!important;
}
.event-result-properties {
    margin-top: 1em;
}

// institution info field
.next-event {
    display: none;
    visibility: hidden;
    // @media screen and (min-width:992px) {
    //     margin-left: 45px;
    // }
    // @media screen and (min-width:1200px) {
    //     margin-left: 75px;
    // }

    @media screen and (min-width:768px) {
        visibility: visible;
        display: block;
        width: 100%;
        max-width: 650px;
        height: auto;
        max-height: calc(60vh - 60px);
        padding: 15px 0;
        overflow-y: auto;

        .event-result {
            padding-bottom: 1em;
            margin-bottom: 1em;
            border-bottom: solid 1px $isc3-blue;
            .event-result-intro {
                margin-top: 0.75em;
            }

            .event-result-properties {
                margin-top: 1em;
            }
        }
        .event-result:first-of-type {
            border-top: solid 1px $isc3-blue;
            padding-top: 1em;
        }
        .event-result:last-of-type {
            margin-bottom: 0em;
        }
    }
}

.atlas-filter,
.event-filter,
.search-filter,
.news-filter {
    background-color: white;
    padding-top: 0;
    padding-bottom: 1em;
    .container {
        padding-bottom: 1em;
        border-bottom: solid 2px $isc3-blue
    }

    .col, [class*="col-"] {
        margin-bottom: 1em;
        @media screen and (min-width:1200px) {
            margin-bottom: 0;
        }
    }

    button {
        width: 100%;
    }
    .dropdown-item {
        font-weight: 300 !important;
    }
    #search-button{
        width: auto;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    select {
        width: 100%;
        font-weight: 400;
        appearance: none;
        border: none;
        border-radius: 5px;
        background: $isc3-blue;
        color: white;
        padding: 5px 40px 5px 10px;
        cursor: pointer;

        background-image: url("../sass/img/arrow_dwn.svg");
        background-repeat: no-repeat;
        background-size: 1.5em 1em;
        background-position: calc(100% - 10px) center;
        background-clip: border-box;

        option{
            width: calc(100% - 1.5em);
            word-break: break-all;
        }
    }
    select:hover {
        transition: color .15s ease-in-out;
        background-color: .15s ease-in-out;
        box-shadow: .15s ease-in-out;
        box-shadow: $isc3-shadow-btn-hover;
    }

    select:active {
        background-color: $isc3-dark-blue !important;
        box-shadow: $isc3-shadow-btn-active !important;
    }

    // select.icn-arrow-down::after {
    //     font-family: 'Font Awesome 5 Free';
    //     content: "\f078";
    //     margin-left: 0.25em;
    //     color: white;
    // }
    select.icn-ani-bounceDown:hover i{
        animation: bounce_down .5s ease-in-out infinite;
    }
}

.atlas-results,
.event-results {
    margin-top: 5em;

    .atlas-result,
    .event-result {
        margin-bottom: 5em;

        .atlas-result-head,
        .event-result-head {
            margin-bottom: 0;

            .col, [class*="col-"] {
                margin: 0 !important;
            }



            .atlas-result-logo,
            .event-result-date {
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 1.5em;
                @media screen and (min-width:768px) {
                    margin-left: 0;
                    margin-right: 1em;
                    margin-bottom: 0em;
                }
            }

            .event-result-date {
                margin-top: 1em;
                margin-bottom: 1em;
                @media screen and (min-width:768px) {
                    margin-top: 0;
                }
            }

            .event-result-subline {
                margin-bottom: 1em;
                @media screen and (min-width:768px) {
                    margin-bottom: 0;
                }
            }

            .event-result-img {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding-left: 15px;
                padding-right: 15px;

                img {
                    max-width: 350px;
                    max-height: 350px;
                }

                img[src=""] {
                   display: none;
                }
            }

            .event-result-properties,
            .atlas-result-properties {
                margin-bottom: 0em;
            }
        }

        .atlas-result-body > .col-md-12 ,
        .event-result-body > .col-md-12  {
             margin-bottom: 0 !important;
        }

        .atlas-result-collapse,
        .event-result-collapse {
            padding: 0 15px;

            @media screen and (min-width:768px) {
                .collapse-cont {
                    padding-top: 1em;
                }
            }

            .event-result-details,
            .atlas-result-details {
                padding-top: 1em;
                padding-bottom: 1em;

                address {
                    margin-bottom: 0;
                }

                .col, [class*="col-"] {
                    margin: 0;
                }

                .google-map {
                    width: 100%;
                    height: 100%;
                    position: relative;
                    overflow: hidden;
                    min-width: 200px;
                    // min-height: 350px;
                    &:empty {
                        display: none;
                    }
                }

                .icn-webevent {
                    width: 150px;
                    // min-width: 150px;
                    height: auto;
                }
            }

            .event-result-details {
                padding-top: 2em;
                padding-bottom: 1em;
            }

            .atlas-result-contact-adress,
            .atlas-result-links {
                margin-bottom: 0!important;
            }
            @media screen and (max-width:768px) {
                .atlas-result-links,
                .atlas-result-contact-adress {
                    margin-top: 1em !important;
                }
            }
            .atlas-result-links,
            .event-result-links {
                // margin-top: 0.5em;
                margin-bottom: 0!important;
                .atlas-result-sm,
                .event-result-sm {
                    font-size: 1.75em;
                    margin-top: 0.25em !important;
                    a {
                        margin-right: 0.1em;
                    }
                }
            }
        }
        .event-result-collapse {
            padding: 0;

            .event-result-details {
                .col:first-child, [class*="col-"]:first-child {
                    margin-bottom: 1em;
                    @media screen and (min-width:768px) {
                        margin-bottom: 0em;
                    }
                }
            }
        }

        .event-result-footer,
        .atlas-result-footer {
            margin-top: 1em;
            a {
                width: fit-content;
            }
            .btn {
                margin-top: 0;
            }
            .col, [class*="col-"] {
                margin: 0 !important;
            }
        }


        .event-result-footer .col, .event-result-footer [class*="col-"]{
            flex-direction: row;
            justify-content: space-between;
            a {
                width: fit-content;
            }
        }

        @media screen and (max-width:768px) {
            .event-result-footer .col, .event-result-footer [class*="col-"] {
                flex-direction: column;
                gap: 10px;
                a {
                    width: 100%;
                }
            }
        }

    }
}

.entry_hint {
    border: solid 2px $isc3-blue;
    width: 100%;
    text-align: center;

    .btn {
        margin: 15px 0;
    }
}



.accordion {
    margin-bottom: 0;

    &.accordion-filter {
        .wrapper {
            // background-color: antiquewhite;
            padding: 15px;
        }
    }
    .card {
        background: none !important;
        border: 1px solid white;
        border-radius: 0.25rem;
        margin: 1rem 0;


        // .icn::before {
        //     font-family: 'Font Awesome 5 Free';
        //     font-weight: 900;
        //     font-style: normal;
        //     // color: white ;
        //     font-size: 1.25rem;
        // }


        .card-header {
            background: none;
            border-bottom: none !important;
            padding: 10px 16px;
            @media screen and (max-width:768px) {
                padding: 10px 8px;
            }

            &:hover {
                background-color:rgba(white, .15);
                transition: background 0.25s ease-in-out;
            }

            .btn-link {
                cursor: pointer;
                display: flex;
                flex-direction: row;
                align-items: center;
                // @media screen and (max-width:768px) {
                //     flex-direction: column;
                // }

                &:hover{
                    text-decoration: none;
                }

                .arrow {
                    text-align: center;
                    min-width: fit-content;
                    // margin-right: 6px;
                    min-width: 1.5rem;
                    i {
                        font-size: 1.5rem;
                        line-height: 1.5;
                        color: white;
                    }
                    transform: rotate(90deg);
                    transition: transform 0.25s ease-in-out;
                    transform-origin: 50% 50%;
                }
                .icon {
                    color: white;
                    font-size: 1.85rem;
                    margin-right: 10px;
                }
                .title {
                    line-height: 5;
                    margin-right: 10px;
                    // padding: 0 16px; // Same as card-body padding!!!
                    // @media screen and (max-width:768px) {
                    //     padding: 0 8px;
                    // }
                    width: 100%;
                        h5 {
                            line-height: 1.15;
                        }
                }
            }
            .btn-link.collapsed .arrow {
                    transform: rotate(0);
                    transition: transform 0.25s ease-in-out;
            }
        }

        .card-body {

            padding: 20px 16px; // Same as title padding!!!;
            @media screen and (max-width:768px) {
                padding: 20px 8px;
            }
            .session-header {
                display: flex;
                flex-direction: row;
                gap: 20px;
                line-height: 1.15 !important;
                width: fit-content;

                .type, .date {
                    text-align: center;
                    display: inline-block;
                    padding: 2px 10px;
                    border: solid 1px white;
                    border-radius: 10px;
                }
            }
            .session-intro {
                margin-top:1.5rem;
                margin-bottom:1.5rem;
            }
            .session-details {
                margin-top: 1.5rem;
                display: flex;
                flex-direction: row;
                gap: 10px;

                @media screen and (max-width:992px) {
                    flex-direction: column;
                }

                .recording-available, .partners, .speakers, .recording {
                    display: flex;
                    flex: 1;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    border: solid 1px white;
                    border-radius: 5px;
                    text-align: center;
                    padding: 20px 10px ;
                    gap: 12px;
                    img {
                        margin-top: 0.25rem;
                        margin-bottom: 0.25rem;
                        // width: 100%;
                        max-width: 150px;
                        padding: 10px;
                        background-color: white;
                        border-radius: 10px;
                    }
                }
                @media screen and (min-width:992px) {
                    .partners{
                        max-width: 250px;
                    }
                }

                a {
                    padding: 6px 24px;
                    // border: solid 2px white;
                    border-radius: 10px;
                    background-color: white;
                    i {
                        &::before {
                            font-family: 'Font Awesome 5 Free';
                            font-size: 2rem;
                            font-style: normal;
                            line-height: 1.5;
                            color: $isc3-blue !important
                        }
                    }
                    &:hover {
                        box-shadow: $isc3-shadow-btn-hover;
                        transition: box-shadow 0.25s ease-in-out;
                    }
                    &:active {
                        box-shadow: $isc3-shadow-btn-active;
                    }
                }
                a.partner {
                    padding: 0;
                }

                .speakers {
                    .wrapper {
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        &.full-width {
                            flex-direction: row;
                            justify-content: center;
                            @media screen and (max-width:992px) {
                                width: fit-content;
                                flex-direction: column;
                                align-content: center;
                            }
                            // .speaker {
                            //     width: fit-content;
                            // }
                        }
                        .speaker {
                            line-height: 1.15;
                            text-align: left;
                            width: fit-content;
                            padding: 0 10px;
                            // background: rgba(white, 0.5);
                            display: flex;
                            flex-direction: row;
                            justify-content: left;
                            gap:0;

                            .speaker-img {
                                padding: 0;
                                align-self: center;
                                min-width: 50px !important;
                                height: 50px;
                                overflow: hidden;
                                clip-path: circle(25px at center);
                                overflow: hidden;
                                position: relative;

                                img {
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                    margin: 0 ;
                                    padding: 0;
                                    width: 50px !important;
                                    height: auto;
                                    border: none;
                                }
                            }
                            .speaker-txt {
                                padding: 0 10px;
                                font-size: 0.85rem;
                                align-self: center;
                                height:fit-content;
                            }

                        }

                    }
                }
                // rendom qu0te
                .des-files {
                    border: solid 1px white;
                    border-radius: 5px;
                    text-align: center;
                    padding: 20px 10px ;
                    gap: 12px;
                    width: 100%;

                    a {
                        display: block;
                        background: none;
                        box-shadow: none;
                        font-weight: 100;
                        line-height: 1.25;
                        padding-left: 20px;
                        text-align: left;
                    }
                }


            }

            .session-agenda {
                border-radius: 0.25rem;
                margin: 1rem 0;
                margin-top:1.5rem;
                padding: 20px 16px; // Same as title padding!!!;
                border: solid 1px white;

                table {
                    background-color: transparent;
                    border-collapse:collapse;
                    line-height: 1.25;

                }
                p {
                    line-height: 1.25;
                    margin-bottom: 0.25rem;
                }
                p:first-child {
                    margin-top: 0;
                }
                p:last-child {
                    margin-bottom: 0;
                }

                ol{
                    li {
                        margin-bottom: 0.25rem;
                    }

                }
                ol:last-child{
                    margin-bottom: 0;
                }

                th {
                    text-align:left
                }

                .dcf-txt-center {
                      text-align: center!important
                    }

                    .dcf-txt-left {
                      text-align: left!important
                    }

                    .dcf-txt-right {
                      text-align: right!important
                    }

                .dcf-table caption {
                      color:#242423;
                      font-size: 1.13em;
                      font-weight: 700;
                      padding-bottom: .56rem
                    }

                    .dcf-table thead {
                      font-size: .84em
                    }

                    .dcf-table tbody {
                      border-bottom: 1px solid white;
                      border-top: 1px solid white;
                      font-size: .84em
                    }

                    .dcf-table tfoot {
                      font-size: .84em
                    }

                    .dcf-table td, .dcf-table th {
                      padding-right: 1.78em
                    }

                    .dcf-table-bordered, .dcf-table-bordered td, .dcf-table-bordered th {
                      border: 1px solid white
                    }

                    .dcf-table-bordered td, .dcf-table-bordered th, .dcf-table-striped td, .dcf-table-striped th {
                      padding-left: 1em;
                      padding-right: 1em
                    }

                    .dcf-table-bordered tr:not(:last-child), .dcf-table-striped tr:not(:last-child) {
                      border-bottom: 1px solid white;
                    }

                    .dcf-table-striped tbody tr:nth-of-type(2n) {
                      background-color: rgba(white, .25);
                    }

                    .dcf-table thead td, .dcf-table thead th {
                      padding-bottom: .75em;
                      vertical-align: bottom
                    }

                    .dcf-table tbody td, .dcf-table tbody th, .dcf-table tfoot td, .dcf-table tfoot th {
                      padding-top: .75em;
                      vertical-align: top
                    }

                    .dcf-table tbody td, .dcf-table tbody th {
                      padding-bottom: .75em
                    }

                    .dcf-table-bordered thead th {
                      padding-top: 1.33em
                    }

                    .dcf-wrapper-table-scroll {
                      overflow-x: auto;
                      -webkit-overflow-scrolling: touch;
                      left: 50%;
                      margin-left: -50vw;
                      margin-right: -50vw;
                      padding-bottom: 1em;
                      position: relative;
                      right: 50%;
                      width: 100vw
                    }

                    @media only screen and (max-width:42.09em) {
                      .dcf-table-responsive thead {
                        clip: rect(0 0 0 0);
                        -webkit-clip-path: inset(50%);
                        clip-path: inset(50%);
                        height: 1px;
                        overflow: hidden;
                        position: absolute;
                        width: 1px;
                        white-space: nowrap
                      }
                      .dcf-table-responsive tr {
                        display: block
                      }
                      .dcf-table-responsive td {
                        -webkit-column-gap: 3.16vw;
                        -moz-column-gap: 3.16vw;
                        column-gap: 3.16vw;
                        display: grid;
                        grid-template-columns: 1fr 2fr;
                        text-align: left!important
                      }
                      .dcf-table-responsive.dcf-table-bordered, .dcf-table-responsive.dcf-table-bordered thead th {
                        border-width: 0
                      }
                      .dcf-table-responsive.dcf-table-bordered tbody td {
                        border-top-width: 0
                      }
                      .dcf-table-responsive:not(.dcf-table-bordered) tbody tr {
                        padding-bottom: .75em
                      }
                      .dcf-table-responsive:not(.dcf-table-bordered) tbody td {
                        padding-bottom: 0
                      }
                      .dcf-table-responsive:not(.dcf-table-bordered):not(.dcf-table-striped) tbody td {
                        padding-right: 0
                      }
                      .dcf-table-responsive.dcf-table-bordered tbody tr:last-child td:last-child {
                        border-bottom-width: 0
                      }
                      .dcf-table-responsive tbody td:before {
                        content: attr(data-label);
                        float: left;
                        font-weight: 700;
                        padding-right: 1.78em
                      }
                    }

                .dcf-overflow-x-auto {
                      overflow-x: auto!important;
                      -webkit-overflow-scrolling: touch
                    }

                .dcf-w-100\% {
                  width: 100%!important;
                }


            }
        }
        .card-footer{
            text-align: center;
            background: none;
            border: none;
            padding-top: 0;
            padding-bottom: 0;
            margin-top: 0;
            margin-bottom: 1.5rem;
        }
    }
}

// 3.3.) Homepage Preview Section
// Homepage latest Article Preview
.preview {
    text-align: center;

    img {
        width: 100%;
        height: auto;
        margin-bottom: 2em;
    }

    @media screen and (min-width: 992px) {
        text-align: left;
        img {
            margin-bottom: 0;
        }
    }
}

// Cards with Overviewtext % links
.link-cards {
    .card {

        // .card-txt {
        //     margin: 1em 30px 2em 30px !important;
        // }
        padding-bottom: 30px !important;

    }
}

// Figure Card with img Figure
.figure-cards {
    // margin-bottom: -3em;

    .figure-card {
        padding: 0;
        // margin-bottom: 3em;

        figure {
            margin: 0;
        }
    }
    @media screen and (min-width:768px) {

        figure {
            display: flex;
            flex-direction: column;
            flex: 1;

            figcaption {
                display: flex;
                flex-direction: column;
                flex: 1;
            }
        }
    }
}

.card-txt .article-container {
    margin-bottom: -2em;
}

.partner-logos a {
    margin: 0 20px 20px 0;
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// 4.) Linktrees
////////////////////////////////////////////////////////////////////////////////////////////////////////////

.linktree {
    margin-top: 2em !important;
    .btn {
        padding-top: 10px;
        padding-bottom: 10px;
        &.full-width {
            padding-top: 15px;
            padding-bottom: 15px;
            margin-top: 15px !important;
            margin-bottom: 15px !important;
            width: 100%;
            &.full-width::before {
                font-size: 1.5em;
                line-height: 1;
                position: relative;
                top: 3px;
            }
        }
    }

    ul {
        margin-top: 1em;
        display: inline-block;
        width: fit-content;
        display: flex;
        flex-direction: column;
        justify-self: center;
        text-align: left;
        line-height: 1.75;

        li {
            margin-bottom: 0.25em;
            &:last-child {
                margin-bottom: 0em;
            }
            padding-left: 0.25em;
            position: relative;
            width: fit-content;
            color: white!important;
            font-weight: 200!important;
            a {
                font-weight: 200!important;
                color: white !important;
            }
        }

        &.text-white li.icn::before {
            position: absolute;
            left: -0.2em;
            top: 0.25em !important;
            font-family: 'Font Awesome 5 Free' !important;
            display: inline-block;
            font-size: 1em;
            line-height: 1em;
            font-weight: 900 !important;
            // margin-right: .25em;
            vertical-align: baseline;
            backface-visibility: hidden;
            transform: translateZ(0);
            -webkit-font-smoothing: subpixel-antialiased;
            color: white !important;
        }
    }



    .sm-links {
        width: 100%;
        height: auto;
        text-align: center;
        a {
            display: inline-block;
            padding: 12px;
            padding-top: 9px;
            margin: 0 5px;
            width: 50px;
            height: 50px;
            border: solid 3px $isc3-blue;
            border-radius: 50%;
            font-size: 1.5em;
            color: $isc3-blue;
            transition: all 0.2s ease-in-out;
        }
        a.youtube i {
            position: relative;
            left: -15%;
            bottom: 5%;
        }
        a.facebook i {
            position: relative;
            left: -6%;
            bottom: 4%;
        }

        a:hover {
            background-color: $isc3-blue;
            color: white;
        }
    }

}


////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// 5.) ADDITIONAL CLASSES
////////////////////////////////////////////////////////////////////////////////////////////////////////////
.developer-comment {
    text-align: center;
    color: white;
    font-style: italic;
    padding: 50px;
    background-color: rgba($isc3-darker-blue, 0.95);
}

.content-elements-section{
    padding-top: 100px;
    padding-bottom: 100px;
}
.content-elements-section:last-of-type {
    padding-top: 100px;
    padding-bottom: 0;
}

.dev-info {
    position: relative;
    padding: 50px;
    @include text-white;
    p {
        font-size: 100%;
    }
    li::before {
        color: white;
    }
}

.dev-info::after {
    content: ' ';
    display: block;
    width: 18px;
    height: 36px;
    position: absolute;
    bottom: 12px;
    left: calc(50% + 36px);
    transform-origin: top left;
    transform: rotate(90deg) translate(45px, 18px);
    background: no-repeat center center url("img/triangle_blue.svg");
    opacity: 1;
}


////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// Talque Styling
////////////////////////////////////////////////////////////////////////////////////////////////////////////

tq-plugin-view {

    .tq-config-palette-primary {
        color: white !important;

        &:hover {
            color: $isc3-blue !important;
        }

        svg {
            stroke: $isc3-blue !important;
        }

    }

    tq-dropdown-icon {
        svg {
            path {
            stroke: $isc3-blue !important;
            }
        }
    }

    label {
        font-weight: 200;
        color: $isc3-dark-blue;
    }

    tq-multiselect-dropdown .the-current {
        color: $isc3-blue !important;
    }

    tq-multiselect-dropdown label {
        font-weight: 200 !important;
    }

    tq-input-search input[type="search"] {
        color: $isc3-blue !important;
        font-weight: 300!important;
    }

    tq-lecture-day-header:hover {
        color: white !important;
    }

    tq-select-tab-header:hover {
        color: $isc3-blue !important;
    }

    .tq-config-button {
        border-style: solid;
        border-radius: 3px;
        min-height: 48px;
        padding: 0 1em;
        border-width: 1px;
        border-color: white;
        color: white;
        background: none;
      }


    .tq-config-button.is-selected, .tq-config-button.is-selected {
        border-style: solid;
        border-color: white;
        color: $isc3-blue;
        background-color: white;
    }

    .tq-config-button:hover, .tq-config-button:hover,
    .tq-config-button.is-selected:hover, .tq-config-button.is-selected:hover {
        background-color: rgba(white,0.75) !important;
        color: $isc3-blue !important;
    }

    tq-lecture-day-header.tq-config-palette-primary:hover {
        color: white !important;
    }

}


////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// Spotlights Agenda
////////////////////////////////////////////////////////////////////////////////////////////////////////////

.spotlights-agenda {
    .article-intro {
        img {
            width: 100% !important;
            height: auto;
        }
    }
    .spotlight {
        border: solid 1px white;
        background-color: rgba(white,.05);
        border-radius: 25px;
        padding: 20px 20px;
        @media screen and (max-width:768px) {
            padding: 20px 10px;
        }

        .spotlight-header {
            display: flex;
            flex-direction: row;
            align-items: center;


            @media screen and (max-width:992px) {
                flex-direction: column;
            }
            .event-result-date {
                margin: 0;
                @media screen and (max-width:992px) {
                    margin-top: 0.5rem;
                    margin-bottom: 1rem;
                }
            }
            .event-title {
                padding: 1em 15px;
                @media screen and (max-width:992px) {
                padding: 0.5em 0;
                }
            }
        }

        // .accordion {

        //     margin-bottom: 0;
        //     .card {
        //         background: none !important;
        //         border: 1px solid white;
        //         border-radius: 0.25rem;
        //         margin: 1rem 0;

        //         .icn::before {
        //             font-family: 'Font Awesome 5 Free';
        //             font-weight: 900;
        //             font-style: normal;
        //             // color: white !important;
        //             font-size: 1.25rem;
        //         }


        //         .card-header {
        //             background: none;
        //             border-bottom: none !important;
        //             padding: 10px 16px;
        //             @media screen and (max-width:768px) {
        //                 padding: 10px 8px;
        //             }

        //             &:hover {
        //                 background-color:rgba(white, .15);
        //                 transition: background 0.25s ease-in-out;
        //             }

        //             .btn-link {
        //                 cursor: pointer;
        //                 display: flex;
        //                 flex-direction: row;
        //                 align-items: center;
        //                 // @media screen and (max-width:768px) {
        //                 //     flex-direction: column;
        //                 // }

        //                 &:hover {
        //                     text-decoration: none;
        //                 }
        //                 .icn {
        //                     min-width: 75px;
        //                     text-align: center;
        //                 }
        //                 .arrow {
        //                     text-align: center;
        //                     .icn {
        //                         // font-size: 1.5rem;
        //                         color: white;
        //                         min-width: 0 !important;
        //                     }
        //                     margin-right: 6px; // Same as card-body padding!!!

        //                     // @media screen and (max-width:768px) {
        //                     //     margin-right: 8px;
        //                     // }
        //                     transform: rotate(90deg);
        //                     transition: transform 0.25s ease-in-out;
        //                 }
        //                 .title {
        //                     line-height: 5;
        //                     margin-right: 10px;
        //                     // padding: 0 16px; // Same as card-body padding!!!
        //                     // @media screen and (max-width:768px) {
        //                     //     padding: 0 8px;
        //                     // }
        //                     width: 100%;
        //                     h5 {
        //                         line-height: 1.15;
        //                     }
        //                 }
        //             }
        //             .btn-link.collapsed .arrow {
        //                     transform: rotate(0);
        //                     transition: transform 0.25s ease-in-out;
        //             }
        //         }

        //         .card-body {

        //             padding: 20px 16px; // Same as title padding!!!;
        //             @media screen and (max-width:768px) {
        //                 padding: 20px 8px;
        //             }
        //             .session-header {
        //                 display: flex;
        //                 flex-direction: row;
        //                 gap: 20px;
        //                 line-height: 1.15 !important;
        //                 width: fit-content;

        //                 .type, .date {
        //                     text-align: center;
        //                     display: inline-block;
        //                     padding: 2px 10px;
        //                     border: solid 1px white;
        //                     border-radius: 10px;
        //                 }
        //             }
        //             .session-intro {
        //                 margin-top:1.5rem;
        //                 margin-bottom:1.5rem;
        //             }
        //             .session-details {
        //                 margin-top: 1.5rem;
        //                 display: flex;
        //                 flex-direction: row;
        //                 gap: 10px;

        //                 @media screen and (max-width:992px) {
        //                     flex-direction: column;
        //                 }

        //                 .recording-available, .partners, .speakers {
        //                     display: flex;
        //                     flex: 1;
        //                     flex-direction: column;
        //                     justify-content: center;
        //                     align-items: center;
        //                     border: solid 1px white;
        //                     border-radius: 5px;
        //                     text-align: center;
        //                     padding: 20px 10px ;
        //                     gap: 12px;
        //                     img {
        //                         margin-top: 0.25rem;
        //                         margin-bottom: 0.25rem;
        //                         // width: 100%;
        //                         max-width: 150px;
        //                         padding: 10px;
        //                         background-color: white;
        //                         border-radius: 10px;
        //                     }
        //                 }
        //                 a {
        //                     padding: 6px 24px;
        //                     // border: solid 2px white;
        //                     border-radius: 10px;
        //                     background-color: white;
        //                     i {
        //                         &::before {
        //                             font-family: 'Font Awesome 5 Free';
        //                             font-size: 2rem;
        //                             font-style: normal;
        //                             line-height: 1.5;
        //                             color: $isc3-blue !important
        //                         }
        //                     }
        //                     &:hover {
        //                         box-shadow: $isc3-shadow-btn-hover;
        //                         transition: box-shadow 0.25s ease-in-out;
        //                     }
        //                     &:active {
        //                         box-shadow: $isc3-shadow-btn-active;
        //                     }
        //                 }
        //                 a.partner {
        //                     padding: 0;
        //                 }

        //                 .speakers {
        //                     .wrapper {
        //                         display: flex;
        //                         flex-direction: column;
        //                         gap: 10px;
        //                         &.full-width {
        //                             flex-direction: row;
        //                             justify-content: center;
        //                             @media screen and (max-width:992px) {
        //                                 width: fit-content;
        //                                 flex-direction: column;
        //                                 align-content: center;
        //                             }
        //                             // .speaker {
        //                             //     width: fit-content;
        //                             // }
        //                         }
        //                         .speaker {
        //                             line-height: 1.15;
        //                             text-align: left;
        //                             width: fit-content;
        //                             padding: 0 10px;
        //                             // background: rgba(white, 0.5);
        //                             display: flex;
        //                             flex-direction: row;
        //                             justify-content: left;
        //                             gap:0;

        //                             .speaker-img {
        //                                 padding: 0;
        //                                 align-self: center;
        //                                 min-width: 50px !important;
        //                                 height: 50px;
        //                                 overflow: hidden;
        //                                 clip-path: circle(25px at center);
        //                                 overflow: hidden;
        //                                 position: relative;

        //                                 img {
        //                                     position: absolute;
        //                                     top: 50%;
        //                                     left: 50%;
        //                                     transform: translate(-50%, -50%);
        //                                     margin: 0 ;
        //                                     padding: 0;
        //                                     width: 50px !important;
        //                                     height: auto;
        //                                     border: none;
        //                                 }
        //                             }
        //                             .speaker-txt {
        //                                 padding: 0 10px;
        //                                 font-size: 0.85rem;
        //                                 align-self: center;
        //                                 height:fit-content;
        //                             }

        //                         }

        //                     }
        //                 }
        //             }
        //         }
        //         .card-footer{
        //             text-align: center;
        //             background: none;
        //             border: none;
        //             padding-top: 0;
        //             padding-bottom: 0;
        //             margin-top: 0;
        //             margin-bottom: 1.5rem;
        //         }
        //     }
        // }

        .btn-intro {
            line-height: 1.5;
            margin-bottom: 0.5rem !important;
        }

    }
}
