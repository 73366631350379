@charset "UTF-8";
////////////////////////////////////////////////////////////////////////////////////////////////////////////
// ISC3.org _typography.scss
// Include of style.scss
// Author: Nils Grassmann
// Copyright: Style & Grace – Design & Advertising (2021)
////////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// Table of Contents
////////////////////////////////////////////////////////////////////////////////////////////////////////////

// 1.) Font Import (Import of Document fonts)
//
// 2.) Main Settings
//
// 3.) Headlines, Paragraphs, Links, ULs
//
// 4.) Text-Helpers (Weight, Colors, Underline, etc)
//
// 5.) Components (Component specific settings for Components found in _components.scss)
//
// 6.) ADDITIONAL CLASSES
//
// X.) LAB (Experimental Code)

////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// 1.) FONT IMPORT
////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //// Fontawesome ////
    // server
    @import '~@fortawesome/fontawesome-free/css/all.min.css';
    @import './fonts/ubuntu/ubuntu.css';
    @import './fonts/etelka/text_pro_bold/stylesheet.css';
    @import './fonts/etelka/light_pro/stylesheet.css';

   // local
    // @import url('fonts/fontawesome/css/all.min.css') screen;
    // @import url('fonts/ubuntu/ubuntu.css') screen;


////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// 2.) MAIN SETTINGS
////////////////////////////////////////////////////////////////////////////////////////////////////////////

html, body {
    font-family:'ubuntu', Arial, Helvetica, sans-serif, sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.75em;
    letter-spacing: 0.03em;
    color: $isc3-dark-grey;
}


////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// 3.) HEADLINES, Ps , LINKS
////////////////////////////////////////////////////////////////////////////////////////////////////////////
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: 'Etelka Text Pro','Ubuntu', sans-serif;
    font-weight: bold;
    font-style: normal;
    color: $isc3-dark-grey;
    hyphens: manual;
}

h2, .h2 {
    font-size: 2em;
    line-height: 1.15em;
    font-weight: 500;
    margin-bottom: 0.25em;
    color: $isc3-blue;
    @media screen and (min-width:768px) {
        font-size: 2.25em;
        line-height: 1.15em;
    }
}

h3, .h3 {
    font-size: 1.75em;
    line-height: 1.15em;
    font-weight: 500;
    margin-bottom: 0.25em;
    color: $isc3-blue;
    // @media screen and (min-width:768px) {
    //     font-size: 1.85em;
    //     line-height: 1.15em;
    // }
}

h4, .h4 {
    // font-family: 'Etelka Light Pro','Ubuntu', sans-serif;
    font-size: 1.3em;
    line-height: 1.35em;
    font-weight: 500;
    margin-bottom: 0.25em;
    @media screen and (min-width:768px) {
        font-size: 1.4em;
        line-height: 1.25em;
    }
}

h5, .h5 {
    font-size: 1.25em;
    line-height: 1.35em;
    font-weight: 500;
    margin-bottom: 0.25em;
}
h6, .h6 {
    font-size: 1em;
    line-height: 1.35em;
    font-weight: 500;
    margin-bottom: 0.25em;
}

p, .paragraph {
    font-size: 1em;
    font-weight: 300;
    line-height: 1.7em;
    margin-top: 0.5em;
    margin-bottom: 1em;
    hyphens: auto;
    @media screen and (min-width:768px) {
        hyphens: manual;
    }
    &:last-child {
        margin-bottom: 0;
    }
}

p.sm {
    font-size: 0.9rem;
}

p.xs {
    font-size: 0.6rem;
}
p.subline {
    font-size: 0.9rem;
    line-height: 1.45;
}

// Blue Bullet point
main ul {
    list-style: none;
    padding-left: 1em;
    // li {
    //     margin-top: 0.25em;
    //     margin-bottom: 0.25em;
    // }
    li::before {
        content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
        color: $isc3-blue; /* Change the color */
        font-weight: 600; /* If you want it to be bold */
        display: inline-block; /* Needed to add space between the bullet and the text */
        width: 1rem; /* Also needed for space (tweak if needed) */
        margin-left: -1rem; /* Also needed for space (tweak if needed) */
        font-size: 150%;
        vertical-align: bottom;
        padding-bottom: 0.05rem;
        position: absolute;
        top: -0.375rem !important;
    }
}
main ol {
        padding-left: 1em;
    li {
        list-style-type: decimal;
    }
    li::before {
        content: none;  /* Add content: \2022 is the CSS Code/unicode for a bullet */
    }
}

main li {
    list-style: none;
    margin-left: 0.5em;
    line-height: 1.45;
    position: relative;
    margin-bottom: 1rem;

}
main li::before {
    content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: $isc3-blue; /* Change the color */
    font-weight: 600; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1rem; /* Also needed for space (tweak if needed) */
    margin-left: -1rem; /* Also needed for space (tweak if needed) */
    font-size: 150%;
    vertical-align: bottom;
    padding-bottom: 0.05rem;
    position: absolute;
    top: -0.25rem;
}


// article p, article ul, article.section p, article.section ul,
// .col p, [class*="col-"] p, .col ul, [class*="col-"] ul {
//     &:first-of-type {
//         margin-top: 0 !important;
//     }
//     &:last-of-type {
//         margin-bottom: 0 !important;
//     }
// }


// section:nth-last-child(1 of p, ul, .col, [class*="col-"]) {
//     margin-bottom: 0 !important;
// }


html main article {
    section {
        h3 {
            color: $isc3-darker-blue;
        }
        h4 {
            font-family:'ubuntu', Arial, Helvetica, sans-serif, sans-serif;
            color: $isc3-blue;
        }
    }
}

html main article.intro {
    h2 {
        color: §isc3-blue;
    }
}

.bg-blue,
.dark-blue,
article.full-width {
    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6,
    p {
        color: white;
    }
}

// article p, article ul, article.section  p, article.section  ul {
//     &:last-of-type {
//         margin-bottom: 0 !important;
//     }
// }

a, a[role="button"],
a:not([href]):not([tabindex]),
a:hover {
    font-weight: 400;
    color: $isc3-blue;
    text-decoration: none;
    cursor: pointer;
}
html.non-touch a:hover,
html.non-touch a[role="button"]:hover,
html.non-touch a:hover:not([href]):not([tabindex]) {
    text-decoration: none !important;
    color: $isc3-dark-blue;
}

blockquote {
    display: inline;
    font-style: italic;
    margin: 0;
    color: $isc3-blue;
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// 4.) Text-Helpers (Weight, Colors, Underline, etc)
////////////////////////////////////////////////////////////////////////////////////////////////////////////


.text-white {
    color: white;
}

.text-darkblue {
    color: $isc3-darker-blue;
}

.text-grey {
    color: $isc3-dark-grey;
}

.text-centered {
    width: 100%;
    text-align: center;
}

.text-left {
    width: 100%;
    text-align: left;
    ul, li {
        text-align: left !important;
    }
}

.text-right {
    width: 100%;
    text-align: right;
}


span.text-centered {
    display: inline-block;
}

.text-light {
    font-weight: 300;
}
.text-bold {
    font-weight: 500;
}
.text-xtrabold {
    font-weight: 900;
}

// .text-underline {
//     margin-bottom: 1em;
// }

.text-underline::after {
    content: ' ';
    display: block;
    height: 3px;
    width: 220px;
    margin: 1.25rem auto 2rem auto;
    background-color: $isc3-blue;
}

.bg-blue,
.dark-blue,
.bg-green,
.full-width {
    .text-underline::after {
        background-color: white !important;
    }
}

.text-blue {
    color: $isc3-blue !important;
}

.text-red {
    color: $isc3-red !important;
}

.text-darker-blue {
    color: $isc3-darker-blue !important;
}

.text-green {
    color: $isc3-green !important;
}


strong {
    font-weight: 700;
    color: $isc3-blue;
}

.feedback {
    line-height: 1.25em;
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// 5.) Components (Typografic settings for Components found in _components.scss)
////////////////////////////////////////////////////////////////////////////////////////////////////////////

.hero-txt {
    color: white;
    letter-spacing: 0.025em;
    overflow: inherit;
    hyphens: auto;
    .h1, h1 {
        font-family: 'Etelka Pro Bold','Ubuntu', sans-serif;
        display: block;
        font-size: 2.5em;
        line-height: 1.25em;
        margin-bottom: 0.5rem;
        font-weight: bold;

        @media screen and (min-width:768px) {
            font-size: 3em;
            line-height: 1.05em;
        }
    }
    .h2, h2 {
        font-family: 'Etelka Light Pro','Ubuntu', sans-serif;
        display: block;
        font-size: 1.75em;
        line-height: 1.35em;
        font-weight: 100;
        margin-bottom: 0.075rem;
        @media screen and (min-width:768px) {
            font-size: 2em;
            line-height: 1.25em;
            margin-bottom: 0.45rem;
        }
    }
    .h3, h3 {
        font-family: 'Etelka Light Pro','Ubuntu', sans-serif;
        display: block;
        font-size: 1.35em;
        line-height: 2em;
        font-weight: 400;
        @media screen and (min-width:768px) {
            font-size: 1.5em;
            line-height: 1.05em;
        }
    }
}

// Atlas & Event Calendar
.atlas-hero,
.event-hero {
    .atlas-info-cont,
    .event-info-cont {
    color: white;
    font-size: 0.95em;
    line-height: 1.25em;

        .event-result {
            h4, h5, h6 {
                color: white;
            }
        }

        .event-result-properties {
            font-size: 0.85em;
            line-height: 1.5em;
            margin-top: 1em;
            margin-bottom: 0.5em;
        }
        a {
            display: inline-block;
            line-height: 1.5em;
        }
    }
}

.atlas-hero {
    .atlas-flag {
        font-size: 1em;
        h6 {
            margin-top: 0.5;
            margin-bottom: 0.125em;
        }
        p {
            font-size: 0.8em;
            line-height: 1.15em;
            padding-bottom: 10px;
        }
    }
}
.event-hero {
    .event-info-cont {
        color: white;
        font-size: 0.95em;
        line-height: 1.25em;

            p {
                font-size: 0.85em;
                line-height: 1.5em;
            }

            .event-result-sm {
                font-size: 1.75em;
            }
    }

    .next-event {
        h2 {
            font-size: 2em;
            margin-bottom: 0.25em;
        }
        h3 {
            font-size: 1.5em;
            margin-bottom: 0.15em;
        }
        h4 {
            font-size: 1.15em;
            // margin-bottom: 0.25em;
        }
        p, .event-result-properties {
            font-size: 0.8em;
            line-height: 1.5em;
            margin-top: 0rem;
            margin-bottom: 0.5em;
        }
    }
}

.atlas-results,
.event-results {

    a {
        display: inline-block;
        line-height: 1.5em;
    }

    .atlas-result,
    .event-result {

        .atlas-result-properties,
        .event-result-properties {
            line-height: 1.5em;
            margin-top: 1em;
        }

        .atlas-result-contact-adress,
        .event-result-adress {
            line-height: 1.5em;
            margin-bottom: 0;
        }

        .atlas-result-links,
        .event-result-links {

            .atlas-result-sm,
            .event-result-sm {
                font-size: 1.75em !important;
            }
            a{
                font-size: 0.95em;
            }
        }

    }

}


.intro {
    // h2 {
    //     margin-bottom: 1.25rem;
    // }
    p {
        font-size: 115%;
    }
}

.preview {
    font-size: 1em;
}

.article-card,
.signin-card,
.atlas-info-cont,
.event-info-cont,
.atlas-flag {
    p {
        margin-top: 0;
    }
    article:first-of-type {
        margin-top: 0;
    }
}
.atlas-flag {
    p {
        line-height: 1.25em !important;
    }
}


// article {
//     margin-top: 3em;
//     margin-bottom: 3em;
// }
// article:last-of-type {
//     margin-bottom: 0;
// }

.cards.link-cards {
    font-size: 95%;
    .card {
        h3 {
            line-height: 1.15em;
            margin-bottom: 0.2em;
        }
        h4 {
            font-size: 1.15em;
            line-height: 1.35em;
            font-weight: 200;
        }
    }
}

footer {
    font-size: 0.85rem;
    font-weight: 100;
    color: white;

    h2 {
        line-height: 1.25em;
        margin-bottom: 0.1em;
        color: $isc3-blue;

    }
    h3 {
        font-size: 1.25em;
        line-height: 1.35em;
    }
    p {
        margin-bottom: 0.35em;
        margin-top: 0;
        line-height: 1.5em;
        color: white;
    }
    a, a:hover, a:active {
        line-height: 0.75em;
        font-weight: 400;
        text-decoration: none;
    }
    a:hover {
        color: $isc3-blue;
    }

    .creator-links {
        margin-top: 1em;
        margin-bottom: 1em;
    }

    .links-sm {
        font-size: 175%;
        a {
            color: $isc3-blue;
            margin-left: 3px;
        }
        a:first-of-type {
            margin-left: 0;
        }
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// 5.) ADITIONAL CLASSES
////////////////////////////////////////////////////////////////////////////////////////////////////////////
