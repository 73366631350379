@font-face {
    font-family: 'Etelka Light Pro';
    src: url('EtelkaLightPro.eot');
    src: url('EtelkaLightPro.eot?#iefix') format('embedded-opentype'),
        url('EtelkaLightPro.woff2') format('woff2'),
        url('EtelkaLightPro.woff') format('woff'),
        url('EtelkaLightPro.ttf') format('truetype'),
        url('EtelkaLightPro.svg#EtelkaLightPro') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

