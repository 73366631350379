@import "mixins";

// .filter-section {
//     background: $isc3-darker-blue;
// }

.hero-capci {
    @media screen and (max-width: 576px) {
        .hero-txt {
            font-size: 0.85rem;
        }
    }

}

// Landing Page
main.capci-database-landing {
    background-image: url("../sass/img/capci-landing-bg.jpg");
    background-size: cover;
    margin-bottom: 0;
    height: 100%;
    padding-top: 50px;
    padding-bottom: 50px;


    .container {
        margin-top: 100px;
        margin-bottom: 100px;
        background-color: $isc3-blue;
        border-radius: 0.25rem;
        padding: 50px;
        .row {
            display: flex;
            flex-direction: row;

            @media screen and (max-width: 992px) {
                flex-direction: column;
            }

            gap: 20px;
            // margin-top: 1rem;
            // margin-bottom: 1rem;
            min-height: 200px;

            .txt-cont {
                padding: 20px;
                display: flex;
                flex-direction: column;
                flex: 6;
                justify-content: center;
                align-items: center;
                a {
                    font-weight: 900;
                }

            }

            // .btn-wrap {
            //     background-color: #cf97fc;
            // }

            .btn-cont {
                flex: 1;
                display: flex;
                flex-direction: column;
                padding: 10px;
                gap: 10px !important;

                @media screen and (max-width: 992px) {
                    flex-direction: row;

                    .btn {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: space-between;
                    }
                }

                @media screen and (max-width: 768px) {
                    flex-direction: column;
                    align-items: center;
                    padding: 0;
                    // .btn {
                    //     max-width: 250px;
                    // }
                }
                // min-width: 300px;
                // min-height: 100px;



                .card-btn {
                    width: 100%;
                    padding: 10px 10px;
                    background-color: $isc3-darker-blue !important;
                    border-radius: 0.25rem;

                    box-shadow: 0 0 10px rgba($isc3-darker-blue, 0.2);

                    img {
                        max-width: 125px;
                        height: auto;
                    }

                    .sm {
                        font-family:'ubuntu', Arial, Helvetica, sans-serif, sans-serif !important;
                        font-weight: 100 !important;
                        margin-top: 0.5rem;
                    }

                }
            }
        }
        .logo-cont {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding: 20px 10%;
            background-color: white;
            min-height: 125px;
            border-radius: 0.25rem;

            @media screen and (max-width: 768px) {
                flex-direction: column;
            }
            @media screen and (min-width: 1200px) {
                justify-content: space-between;
            }
        }
    }

    a.text-darkblue:hover{
        color: $isc3-darker-blue !important;
    }
}

//Filters
.capci-database, .filter-section {

    .icon-cont {
        // width: 100%;
        // height: 100%;

        .icon {
            width: 100%;
            height: 100%;
            display: grid;
            white-space: pre-wrap;
            min-height: 65px;
        }
        &.f-awesome .icon::after {
            font-family: "Font Awesome 6 Free";
            font-weight: 900;
            font-size: 2.5rem;
            // line-height: 1.75;
            color: white;
            text-align: center;
            float: center;
            margin: auto;

        }
        &.short-code .icon::after {
            font-family: "ubuntu", Arial, Helvetica, sans-serif, sans-serif;
            font-size: 2rem;
            font-weight: 600;
            color: white;
            text-align: center;
            float: center;
            margin: auto;
            line-height: 1;
        }
        &.bg-img .icon {
            width: 100%;
            height: 100%;
            // background-image: url("../sass/img/ghg_reduction.svg");
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            &::after {
                content: none;
            }
        }

        &:active {
            background-color: rgba(white, 0.5) !important;
            // background-color: pink !important;
        }
    }

    .grid-wrapper {
        max-width: 100%;
        display: grid;
        grid-auto-columns: 1fr;
        grid-auto-rows: 1fr;

        //mobile
        grid-template-columns: repeat(1, 1fr);
        grid-column-gap: 10px;
        grid-row-gap: 10px;

        //tablet
        @media screen and (min-width: 768px) {
            grid-template-columns: repeat(2, 1fr);
            grid-row-gap: 20px;
        }

        //desktop
        @media screen and (min-width: 992px) {
            grid-template-columns: repeat(4, 1fr);
        }

        .item {
            font-family: "ubuntu", Arial, Helvetica, sans-serif, sans-serif;
            display: grid;
            align-content: center;
            // background-color: $isc3-blue;
            line-height: 1.25;
            border-radius: 0.25rem !important;
            padding: 5px;
            margin: 0;
            white-space: normal;
            min-height: 60px;
        }
    }

    .filter {
        .grid-wrapper {
            padding-left: 15px;
            padding-right: 15px;
            display: grid;

            //mobile
            grid-auto-columns: 1fr;
            grid-template-columns: repeat(1, 1fr);
            grid-column-gap: 20px !important;

            //tablet
            @media screen and (min-width: 768px) {
                grid-template-columns: repeat(2, 1fr);
            }

            //desktop
            @media screen and (min-width: 992px) {
                grid-template-columns: repeat(4, 1fr);
            }
        }

        .select {
            .select-toggle {
                display: flex;
                flex-direction: row;
                gap: 1rem;
                align-items: center;

                .select,
                .unselect {
                    cursor: pointer;
                    margin-bottom: 1.5rem;
                    user-select: none;
                }
                .select {
                    color: #8ec477;
                }
                .unselect {
                    color: #e52421;
                }
            }

            .item {
                position: relative;
                background: none !important;
                border: solid 1px white;
                padding: 0;

                display: grid;
                grid-template-columns: 65px minmax(0, 3fr);
                grid-template-rows: minmax(65px, 1fr);

                .icon {
                    border-top-left-radius: 0.2rem;
                    border-bottom-left-radius: 0.2rem;
                }

                .name {
                    display: grid;
                    white-space: pre-wrap;
                    &::after {
                        text-align: left;
                        color: white;
                        font-size: 0.85rem;
                        line-height: 1.45;
                        margin: auto 5px auto 10px;
                    }
                }

                .hook {
                    position: absolute;
                    top: -0.7rem;
                    left: -0.7rem;
                    width: 30px;
                    height: 30px;
                    background-image: url("../sass/img/circle-check-solid.svg") !important;
                    background-position: center center;
                    background-repeat: no-repeat;
                    filter: drop-shadow(0 0 5px rgba($isc3-darker-blue, 0.2));
                }
                &.appear {
                    .hook {
                        display: block;
                        transition: 0.15s
                        cubic-bezier(0.865, 0.015, 0.945, 0.68);
                        transition-property: color, scale, opacity;
                        scale: 1;
                        opacity: 1;
                    }
                }

                &.disappear {
                    .hook {
                        // display: block;
                        transition: 0.15s
                        cubic-bezier(0.865, 0.015, 0.945, 0.68);
                        transition-property: color, scale, opacity;
                        scale: 2;
                        opacity: 0;
                    }
                }

                &.short-code .icon::after {
                    font-size: 100%;
                    line-height: 1;
                }

                &.bg-img .icon {
                    background-size: 90%;
                }
            }

            .item.no-icon {
                display: flex;
                justify-content: center;
                align-items: center;
                .name {
                    display: inline !important;
                    color: white;
                    height:fit-content;
                    font-size: 0.85rem;
                    line-height: 1.45;
                }
            }
        }

        .search {
            height: calc( 150px + 1rem ); // create button distance to counter .input-group-append{position:absolute}

            .input-group {
                // Glas Icon
                .input-group-prepend {
                    .input-group-text {
                        background-color: white;
                        border: 1px solid white !important;

                        .icon {
                            color: $isc3-darker-blue;
                            font-size: 1.85rem;
                            margin-right: 4px;
                        }
                    }
                }

                .icon {
                    font-family: "Font Awesome 6 Free";
                    font-weight: 900;
                }

                // Text Field
                input {
                    flex: 1 1 auto;
                    width: 1%;
                    background: none;
                    border: 1px solid white !important;
                    color: white;
                    border-radius: 0.25rem;
                    min-height: 50px;
                    position: relative;

                    &::placeholder {
                        color: white !important;
                    }
                    // border-top-left-radius: 0 !important;
                    // border-bottom-left-radius: 0 !important;
                }

                // Search Button
                .input-group-append {
                    position: absolute;
                    top: calc(50px + 1rem);
                    left: 0;
                    height: auto;
                    min-height: 50px;
                    width: 100%;
                    min-width: 80px;
                    border-radius: 0;

                    button.btn {
                        width: 100%;
                        padding: 0 30px;
                        border-radius: 0.25rem;
                        cursor: pointer;
                        font-size: 1.15rem;
                        color: $isc3-darker-blue !important;

                        &:active {
                            background-color: rgba(white, 1) !important;
                            box-shadow: $isc3-shadow-btn-active !important;
                            scale: 1;
                            // background-color: pink !important;
                        }
                    }
                }
            }

            // @media screen and (min-width: 768px) {
            //     height: auto; // // delete button distance to counter .input-group-append{position:relative}

            //     .input-group {
            //         input {
            //             margin-bottom: 0;
            //             position: relative;
            //             border: 0;
            //             display: block;
            //             border-radius: 0 !important;
            //         }

            //         .input-group-append {
            //             width: auto;
            //             position: relative;
            //             right: 0;
            //             top: 0;
            //             button.btn {
            //                 border-top-left-radius: 0;
            //                 border-bottom-left-radius: 0;
            //             }
            //         }
            //     }
            // }
        }

        .sort {
            .input-group {
                flex-direction: column;

                select {
                    min-width: 200px;
                    text-align: center;
                    padding: 0 20px;
                    min-height: 50px;
                    background-color: white;
                    appearance: none; // hide arrow
                    display: block;


                    margin-bottom: 0;
                    &:first-of-type {
                        margin-bottom: 0.5rem;
                    }
                    @media screen and (min-width: 768px) {
                        &:first-of-type {
                            margin-right: 0.5rem;
                            margin-bottom: 0;
                        }
                    }
                }

                @media screen and (min-width: 768px) {
                    flex-direction: row;
                    select {
                        width: auto;
                        &:first-of-type {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }

    &.blue {
        .filter {
            .select-toggle {

                .select {
                    color: White !important;
                }

                .unselect {
                    color: white !important;
                }
            }

            .search {
                color: isc3-blue;
                height: calc( 100px + 1rem ) !important;
                // border-radius: 0.25rem !important;

                .icon {
                    color: $isc3-blue !important;
                }
                .input-group .input-group-append button.btn {
                    color: $isc3-blue !important;
                }
                // .input-group-prepend {
                //     border-right: none !important;
                //     .input-group-text {
                //         background-color: $isc3-blue !important;
                //         border-right: none !important;
                //         border-top-right-radius: none !important;
                //         border-bottom-right-radius: none !important;

                //         i {
                //             color: white !important;
                //         }
                //     }
                // }
                input {
                    // border-left: none !important;
                    // border-top-left-radius: none !important;
                    // border-bottom-left-radius: none !important;
                    // &::placeholder {
                    //     padding-left: 1rem;
                    //     color: pink !important;
                    // }
                    padding-left: 1.25rem;
                    padding-right: 1.25rem;
                }
            }

            .sort {
                .input-group select {
                    color: $isc3-blue !important;
                    border: none !important;
                }
            }
        }
    }

    .best-practice,
    .identifikation-key,
    .life-cycles,
    .contact {
        //detail Wrapper for Best Prtactices & Identifiction Keys

        .item {
            background-color: $isc3-lighter-grey;
            padding: 15px;
        }
        .wrapper {
            padding: 15px;
            box-shadow: 0 0 10px rgba($isc3-darker-blue, 0.2);
            background-color: white;
            border-radius: 0.4rem;
            &:empty {
                display: none;
            }
        }
        .flex-item, .intro-item {
            padding: 15px;
            background-color: $isc3-lighter-grey;
            border-radius: 0.25rem;
            overflow: hidden;
            color: $isc3-dark-grey !important;

            margin-bottom: 1.5rem;
            &:last-child {
                margin-bottom: 0;
            }
            @media screen and (min-width: 768px) {
                margin-bottom: 0;
            }

            &.flex {
                padding: 0;
                display: flex;
                flex-direction: column;
                // gap: 20px;

                .icon-cont {
                    min-width: 125px;
                }
                .text-cont {
                    padding: 15px;
                }

                &.drivers-barriers {
                    background: none;
                    flex-direction: column;
                }

                @media screen and (min-width: 768px) {
                    flex-direction: row !important;
                }

                &.horizontal-center {
                    gap:10px;
                    overflow: visible;
                    background: none;
                    a {
                        flex: 1;
                        background: white;
                        border-radius: 0.25rem;
                        box-shadow: 0 0 10px rgba($isc3-darker-blue, 0.2);
                        padding: 30px 15px;
                        color: $isc3-dark-grey;
                        cursor: pointer;

                        p {
                            line-height: 1.5;
                        }

                        .icon::after {
                            font-size: 3rem;
                        }

                        &:hover {
                            .icon {
                                animation: pump 1s ease-in infinite;
                            }
                        }

                        &:active {
                            box-shadow: 0 0 3px rgba($isc3-darker-blue, 0.2);
                        }
                    }

                }
            }


            background-color: $isc3-lighter-grey;

        }

        .grid-wrapper {
            grid-row-gap: 10px !important;
            .btn {
                background-color: $isc3-blue;
            }
        }

        .grid-wrapper.grid-235 {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr;
            grid-auto-rows: min-content;

            gap: 10px;
            overflow: hidden;

            @media screen and (min-width: 768px) {
                grid-template-columns: repeat(2, 1fr);
                grid-row-gap: 10px;
            }

            //desktop
            @media screen and (min-width: 992px) {
                grid-template-rows: 1fr 1fr;
                grid-template-columns: 2fr 3fr 5fr;
                gap: 10px;
                overflow: hidden;
            }

            .item-0 {
                background-color: $isc3-blue;
                color: white;

                @media screen and (min-width: 992px) {
                    grid-row-start: 1;
                    grid-column-start: 1;

                    grid-row-end: 3;
                    grid-column-end: 2;
                }

                .icon-cont, icon-cont .icon {
                    display: block;
                    height: 100px;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                }
            }

            .item-1 {


                background-color: $isc3-lighter-grey;
                justify-self: flex-start;



                .header {

                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;


                    .icon-cont {
                        min-width: 75px;
                        min-height: 75px;
                    }

                    h4 {
                        display: flex;
                        align-items: center;
                    }
                }

                .body {
                    @media screen and (min-width: 762px) {
                        padding-right: 10px;
                        max-height:18rem;
                        overflow-y: scroll;
                    }
                    @media screen and (min-width: 992px) {
                        max-height:18rem;
                        overflow-y: scroll;
                    }

                }




                @media screen and (min-width: 992px) {
                    grid-row-start: 1;
                    grid-column-start: 2;

                    grid-row-end: 3;
                    grid-column-end: 3;
                }
            }

            .item-2 {

                display: grid;
                padding: 0;
                overflow: hidden;
                background-color: $isc3-lighter-grey;


                .icon-cont {
                    .icon::after {
                        font-size: 3rem;
                    }
                }

                .txt-cont {
                    padding: 15px;
                }

                @media screen and (min-width: 992px) {
                    grid-template-columns: 1fr 4fr;
                    grid-row-start: 1;
                    grid-column-start: 3;

                    grid-row-end: 2;
                    grid-column-end: 4;
                    align-content: stretch;


                    .icon-cont {
                        border-top-left-radius: 0.25rem !important;
                        border-bottom-left-radius: 0.25rem !important;
                        overflow: hidden;
                    }
                    .txt-cont {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                    }

                }
            }
            .item-3 {
                background-color: $isc3-lighter-grey;
                padding: 0 !important;

                display: flex;
                flex-direction: column;
                justify-content: center;

                .header {
                    padding: 10px;
                }

                .body {
                    height: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: stretch;
                    // min-height: 100px;
                    // max-height: 100px;

                    overflow: hidden;

                    .short-code {
                        flex: 1;
                        border-radius: 0;
                            .icon {
                                &::after {
                                    font-size: 1.5rem;
                                    line-height: 1;
                                    padding: 10px;
                                }
                            }

                    }

                }


                @media screen and (min-width: 992px) {
                    grid-row-start: 2;
                    grid-column-start: 3;

                    grid-row-end: 3;
                    grid-column-end: 4;

                    .body {
                        max-height: none;
                        height: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: stretch;

                        border-bottom-left-radius: 0.25rem !important;
                        border-bottom-right-radius: 0.25rem !important;
                        overflow: hidden;
                    }
                }
            }
        }

        .accordion {
            .card {
                border-color: $isc3-blue;
                background-color: $isc3-lighter-grey !important;
                .title h5, .icon i, .arrow i {
                        color: $isc3-blue !important;
                        margin-bottom: 0;
                }

                .card-body {
                    .img-cont {
                        text-align: center;
                        width: 100%;
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                }
            }
        }
    }

    .life-cycles .item {
        display: flex;
        flex-direction: row;
        gap: 15px;
        border-radius: 0.25rem;
        margin-bottom: 1rem;
        min-height: 150px;
        .icon-cont {
            margin-top:-15px;
            margin-bottom:-15px;
            margin-left: -15px;
            flex: 1;
            background-color: $isc3-blue;
            border-top-left-radius: 0.25rem;
            border-bottom-left-radius: 0.25rem;
            .icon {
                background-size: contain;
            }
        }
        .txt-cont {
            flex: 7;
        }

        @media screen and (max-width: 768px) {
            flex-direction: column;

            .icon-cont {
                margin: -15px -15px 0 -15px;
                border-top-left-radius: 0.25rem;
                border-bottom-left-radius: 0rem;
                border-top-right-radius: 0.25rem;
                .icon {
                    min-height: 100px;

                }
            }
        }

    }

    .contact {
        .wrapper {
            padding-top: 50px;
            padding-bottom: 50px;

            .fa-comment {
                font-size: 5rem;
            }

            address {
                .address {
                    font-size: 1rem;
                    font-weight: 500;
                    line-height: 1.25;

                    .isc3 {
                        font-family: 'Etelka Text Pro','Ubuntu', sans-serif;
                        font-size: 2rem;
                        font-weight: 900;
                    }
                }
                .implemented {
                    line-height: 1.35;
                }
            }

            @media screen and (max-width: 768px) {
                padding-top: 0;
                padding-bottom: 0;
                box-shadow: none;
            }

        }

    }

    // Legend
    .legend {
        position: fixed;
        top: 100px;
        right: 25px;
        @media screen and (min-width: 992px) {
            top: 120px;
            right: 40px;
        }
        z-index: 5;

        background-color: $isc3-blue;
        border-radius: 0.25rem;
        cursor: pointer;

        .icon {
            position: relative;
            width: 65px;
            height: 65px;
            border-radius: 0;

            &::after {
                position: relative;
                display: block;

                font-size: 2.5rem !important;
                line-height: 1.5;
                font-weight: 900;
            }
        }

        .legend-item {
            height: 0;
        }
        .legend-item .icon,
        .legend-item .icon::after {
            height: 0;
            opacity: 0;
            display: none;
        }

        &.selected {
            .legend-item {
                height: 100%;
            }
            .legend-item .icon,
            .legend-item .icon::after {
                height: 65px;
                opacity: 1;
                display: block;
            }
        }

        .legend-title {
            font-size: 0.85rem;
            color: white;
            text-align: center;
        }

        .book {
            height: 75px;
            width: 65px;
            opacity: 1 ;
            display: block;
        }
    }

    // Identification Key Icons
    .ee {
        .icon {
            background-color: #d6dfef;
            &::after {
                content: "EE";
            }
        }
        .name::after {
            content: "Energy Efficency";
        }
    }
    .pat {
        .icon {
            background-color: #acc7e4;
            &::after {
                content: "PP";
                white-space: pre-wrap;
            }
        }
        .name::after {
            content: "Processes and Technology";
            white-space: pre-wrap; /* or pre-wrap */
        }
    }
    .p {
        .icon {
            background-color: #82a8d9;
            &::after {
                content: "P";
            }
        }
        .name::after {
            content: "Product";
        }
    }
    .erd {
        .icon {
            background-color: #ffe2d6;
            &::after {
                content: "ERD";
            }
        }
        .name::after {
            content: "Emission Reduction";
        }
    }
    .re {
        .icon {
            background-color: #ffae86;
            &::after {
                content: "RE";
            }
        }
        .name::after {
            content: "Resource Efficiency";
        }
    }
    .TR,.tr {
        .icon {
            background-color: #d1cece;
            &::after {
                content: "TR";
            }
        }
        .name::after {
            content: "Technology Optimization / Refurbishment";
        }
    }
    .er {
        .icon {
            background-color: #959595;
            &::after {
                content: "ER";
            }
        }
        .name::after {
            content: "Energy Recovery";
        }
    }
    .rs {
        .icon {
            background-color: #ffefce;
            &::after {
                content: "RS";
            }
        }
        .name::after {
            content: "Renewable Sources";
        }
    }
    .ji {
        .icon {
            background-color: #ffe39b;
            &::after {
                content: "JI";
            }
        }
        .name::after {
            content: "Joint Initiatives";
        }
    }
    .ic {
        .icon {
            background-color: #ffd86c;
            &::after {
                content: "IC";
            }
        }
        .name::after {
            content: "Industrial Cooperation";
        }
    }
    .bm {
        .icon {
            background-color: #dfecda;
            &::after {
                content: "BM";
            }
        }
        .name::after {
            content: "Business Models";
        }
    }
    .r {
        .icon {
            background-color: #c3dfb5;
            &::after {
                content: "R";
            }
        }
        .name::after {
            content: "Regulation";
        }
    }
    .fs-stock, .amaf {
        .icon {
            background-color: #c9cdfd;
            &::after {
                content: "FS STOCK";

            }
        }
        .name::after {
            content: "Alternative Materials and Feedstocks";
        }
    }
    .ps {
        .icon {
            background-color: #cf97fc;
            &::after {
                content: "PS";
            }
        }
        .name::after {
            content: "Process Simulation";
        }
    }
    .pat, .pt {
        .icon {
            background-color: #cf97fc;
            &::after {
                content: "PP";
            }
        }
        .name::after {
            content: "Process and Technology";
        }
    }
    .rar {
        .icon {
            background-color: #ff96fc;
            &::after {
                content: "RR";
            }
        }
        .name::after {
            content: "Research and Reports";
        }
    }
    .madt {
        .icon {
            background-color: #cb8e18;
            &::after {
                content: "MT";
            }
        }
        .name::after {
            content: "Management and Digital Tools";
        }
    }
    .dt {
        .icon {
            background-color: #d465fb;
            &::after {
                content: "DT";
            }
        }
        .name::after {
            content: "Digital Twins";
        }
    }
    .vrar {
        .icon {
            background-color: #ff64fb;
            &::after {
                content: "VR/AR";
            }
        }
        .name::after {
            content: "Virtual Reality / Augmented Reality";
        }
    }

    // life cycle icons
    .ovr, .lf-o {
        .icon {
            background-image: url("../sass/img/overarching.svg") !important;
            background-position: center center;
            background-repeat: no-repeat;
            &::after {
                content: none;
            }
        }
        .name::after {
            content: "Overarching";
        }
    }
    .inpt, .lf-i {
        .icon {
            background-image: url("../sass/img/inputs.svg") !important;
        }
        .name::after {
            content: "Inputs";
        }
    }
    .npo, .lf-npo {
        .icon {
            background-image: url("../sass/img/npout.svg") !important;
            &::after {
                content: none;
            }
        }
        .name::after {
            content: "Non Product Output";
        }
    }
    .rec, .lf-rrr {
        .icon {
            background-image: url("../sass/img/recycling.svg") !important;
            background-position: center center;
            background-repeat: no-repeat;
            &::after {
                content: none;
            }
        }
        .name::after {
            content: "Recycling / Recirculation / Reuse";
        }
    }
    .pap, .lf-pap {
        .icon {
            background-image: url("../sass/img/process.svg");
            background-position: center center;
            background-repeat: no-repeat;
            &::after {
                content: none;
            }
        }
        .name::after {
            content: "Processes and Products";
        }
    }

    // reversed icons
    .ovr-rev, .lf-o-rev {
        .icon {
            background-image: url("../sass/img/overarching_rev.svg");
        }
    }
    .inpt-rev, .lf-i-rev {
        .icon {
            background-image: url("../sass/img/inputs_rev.svg");
        }
    }
    .npo-rev, .lf-npo-rev {
        .icon {
            background-image: url("../sass/img/npout_rev.svg");
        }
    }
    .rec-rev, .lf-rrr-rev {
        .icon {
            background-image: url("../sass/img/recycling_rev.svg");
        }
    }
    .pap-rev, .lf-pap-rev {
        .icon {
            background-image: url("../sass/img/process_rev.svg");
        }
    }

    // Solution Maturity icons
    .ud {
        .icon {
            background-color: #ff7a00;
            &::after {
                content: "\f085";
            }
        }
        .name::after {
            content: "Under Development";
        }
    }
    .new {
        .icon {
            background-image: url("../sass/img/new.svg");
            background-color: #f2cd00;
            background-size: 75%;
            &::after {
                content: none;
            }
        }
        .name::after {
            content: "Newly Established";
        }
    }
    .we {
        .icon {
            background-color: #68c342;
            &::after {
                content: "\f164";
            }
        }
        .name::after {
            content: "Well Established";
        }
    }

    // Green House Gas Reduction
    .ghg {
        .icon {
            background-image: url("../sass/img/ghg_reduction.svg");
            background-position: center center;
            // background-size: 75%;


            width: 100%;
            height: 100%;
            &::after {
                content: none;
            }
        }
    }

    //Trainings
   .training-on-demand-training {
        .icon {
            background-image: url("../sass/img/icn_on-demand-training.svg");
            background-color: white;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 75%;
            width: 100%;
            height: 100%;
            &::after {
                content: none;
            }
        }
        .name::after {
            content: "on-demand training";
        }
    }
    .training-self-paced-online-learning {
        .icon {
            background-image: url("../sass/img/icon_training-self-paced.svg");
            background-color: white;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 75%;
            width: 100%;
            height: 100%;
            &::after {
                content: none;
            }
        }
        .name::after {
            content: "self-paced online learning";
        }
    }
    .training-study-courses {
        .icon {
            background-image: url("../sass/img/icn_education.svg");
            background-color: white;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 75%;
            width: 100%;
            height: 100%;
            &::after {
                content: none;
            }
        }
        .name::after {
            content: "study courses";
        }
    }
    .training-online-library {
        .icon {
            background-image: url("../sass/img/icn_recording.svg");
            background-color: white;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 75%;
            width: 100%;
            height: 100%;
            &::after {
                content: none;
            }
        }
        .name::after {
            content: "online library";
        }
    }


    // Legend Book
    .book {
        .icon {
            min-height: 0 !important;
            margin-top: -15px;
        }
        .icon::after{
            content: "\f518";
        }
    }

    .btn-back {
        width: 40px;
        height: 40px;
        position: fixed;
        bottom: 20px;
        left: 10px;
        @media screen and (min-width: 992px) {
            left: 40px;
        }
        background-color: $isc3-blue;
        border-radius: 0.25rem;
        cursor: pointer;
        z-index: 5;
        .icon {
            min-height: 0 !important;
            &::after {
                content: "\f053";
                font-size:20px !important;
                line-height: 0.5;
            }
        }
    }


    .globe {
        .icon::after{
            content: "\f57c";
            color: $isc3-blue !important;
        }
    }

    .pdf {
        .icon::after{
            content: "\f1c1";
            color: $isc3-blue !important;
        }
    }

    //Drivers Barriers
    .drivers {
        .icon {
            min-height: 100px !important;
            background-image: url("../sass/img/drivers.svg");
        }
    }
    .barriers {
        .icon {
            min-height: 100px !important;
            background-image: url("../sass/img/barriers.svg");
        }
    }


    .text-red {
        color: #d66b00 !important;
    }

}

// Button Styling
article.full-width.filter-section .container .btn, article.full-width.filter-section .container html.non-touch .btn:hover, article.full-width.filter-section .container .btn:active {
    &:active {
        background-color: rgba(white, 0.25) !important;
        box-shadow: none !important;
        transform: scale(0.975);
    }
}

// Modals
.modal.capci-modal {
    .modal-content .modal-header .close {
        color: rgba(white, 0.75) !important;
        text-shadow: none;
        &:hover {
            color: rgba(white, 1) !important;
        }
    }

    .modal-content .modal-title .icon-cont .icon {
        display: inline-block;
        width: 100px;
        height: 100px;
        border-radius: 0.25rem ;
        &::after {
            font-size: 1.6rem !important;
            line-height: 1 !important;
            display: inline-block;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .modal-content .modal-title .icon-cont.f-awesome .icon::after {
        font-size: 3rem !important;
    }

    .modal-content .modal-title .icon-cont.bg-img .icon {
        background-size: 75%;
    }

    .modal-content .modal-title .icon-cont.bg-img.full-size .icon{
        background-size: contain;
    }

    .modal-content {
        background-color: $isc3-blue;
        color: white !important;
        h2,
        h3,
        h4,
        h5,
        h6,
        p {
            color: white;
        }
    }

    .btn-white {
        background-color: white !important;
        color: $isc3-blue !important;
    }
}

.link-cont {
    &:empty {
        display: none !important;
    }
    &:last-child {
        margin-bottom: 0 !important;
    }
}

.link-cont:empty {
        display: none !important;
}


