@charset "UTF-8";
////////////////////////////////////////////////////////////////////////////////////////////////////////////
// ISC3.org _navigations.scss
// Include of style.scss
// Author: Nils Grassmann
// Copyright: Style & Grace – Design & Advertising (2021)
////////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// Table of Contents
////////////////////////////////////////////////////////////////////////////////////////////////////////////

// 1.) INFO (Informations about the File)
//
// 2.) CODE FOR MOBILE VIEVPORT
//  2.1.) LAYOUT MOBILE
//  2.2.) INTERACTIONS
//
// 3.) CODE FOR TABLET / VIEWPORT (768px+)
//  3.1.) LAYOUT TABLET

// 4.) CODE FOR DESKTOP / VIEWPORT (992px+)
//  4.1.) LAYOUT DESKTOP
//
// X.) LAB (Experimental Code)

////////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////////
// 1.) INFO
////////////////////////////////////////////////////////////////////////////////////////////////////////////

// • Mobile first Layout
// • ViewPort Changes at min-width:768px, 992px, 1200px

////////////////////////////////////////////////////////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////////////////////////////////////////////////
// 2.) CODE FOR MOBILE VIEVPORT
////////////////////////////////////////////////////////////////////////////////////////////////////////////


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // 2.1.) LAYOUT MOBILE
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////

    #nav-section {
        position: sticky;
        top: 0;
        width: 100%;
        height: $nav-height-mob;
        background-color:rgba( white, 0.95);
        z-index: 99;

        .container {
            position: relative;
            height: $nav-height-mob;
            padding: 0;
            z-index:0;
        }

        #logo {
            img {
                margin: 5px 0 0 25px;
                @media screen and (min-width: $mobile-large) {
                    margin: 5px 0 0 15px;
                }
                height: 75px;
            }
        }

        #btn-menu {
            position: absolute;
            top: calc(#{$nav-height-mob} - 45px);
            right: 25px;
            cursor: pointer;
            color: white;
            user-select: none;
            background-color: $isc3-blue;
        }
        #btn-menu:hover {
            background-color: $isc3-dark-blue;

        }

        #btn-menu.clear-hover {
            background-color: $isc3-blue !important;
            box-shadow: none !important;
        }

        #btn-menu::after {
            font-family: 'Font Awesome 5 Free';
            content: "\f0c9";
            font-weight: 600;
            line-height: 1rem;
            cursor: pointer;
            margin-left: 0.25rem;
        }

        nav {
            position: absolute;
            top: $nav-height-mob;
            right: 0;
            width: 100%;
            padding: 25px;
            text-align: right;
            background-color: rgba($isc3-darker-blue, 0.95);
            // background-blend-mode: multiply;
            // z-index: 99;
            user-select: none;
            display: none;
            overflow: hidden;
            ul#menu-main {
                margin-top: 50px;
                margin-bottom: -0.05rem;
                padding: 2px 0 0 0;
                border-top: solid 1px $isc3-blue;

                max-height: calc(100vh - #{$nav-height-mob} - 85px);
                overflow-y: scroll;
                overflow-x: hidden;
                font-size: 1.2rem;
                font-weight: 1 !important;
                line-height: 1.35;
                letter-spacing: .05em;

                ul {
                    padding: 0;
                }

                li {
                    list-style-type: none;
                    margin:  1rem 0;
                    outline: none;

                    a, a:active, a:visited, a:hover {
                        padding: 0.3rem 0;
                        color: white;
                        text-decoration: none;
                        outline: none;
                        font-weight: 100;
                    }
                    a:hover, a:active, a:focus {
                        color: $isc3-blue;
                    }
                    a::before {
                        font-family: 'Font Awesome 5 Free';
                        content: '\f15b';
                        margin-right: 0.25em;
                        font-weight: 600;
                        color: $isc3-blue;
                        line-height: 0;
                        display: inline-block;
                    }
                    ul li {
                        margin-right: 25px;
                    }
                }
                li.li-active > a {
                    color: white;
                    font-weight: 500;
                }
                li.li-active > a:hover {
                    color: $isc3-blue;
                }

                li.multilevel a {
                    display: inline-block;
                }
                li.multilevel > a::before {
                    content: '\f137';
                }

                li.li-active > a::before {
                    content: '\f15b';
                }




                // Hide Scrollbar
                -ms-overflow-style: none;  /* IE and Edge */
                scrollbar-width: none;  /* Firefox */
            }
            // Hide Scrollbar
            ul#menu-main::-webkit-scrollbar {
                display: none;
                box-shadow: none;
                background-color: transparent;
            }
        }

        ul#menu-add,
        ul#menu-add-user {
            position: absolute;
            top: $nav-height-mob;
            left: 0;
            right: auto;
            height: 30px;
            margin:  25px;
            padding: 0;
            z-index: 99;
            white-space: nowrap;
            font-size: 0;

            li {
                font-size: 1rem;
                display: inline;
                margin-right: 3px;
            }

            li:last-of-type{
                margin-left: 5px;
            }

            a, a:focus {
                // color: white;
                text-decoration: none;
                outline: none;
                text-align: center;
            }
        }
        ul#menu-sm {
            position: absolute;
            top: $nav-height-mob;
            left: auto;
            right: 0;
            margin: 25px;
            z-index: 99;
            li {
                display: inline;
                margin-left: 5px;
            }
            a {
                font-size: 1.75rem;
                color: $isc3-blue;
            }

            a:hover, a:active {
                color: $isc3-dark-blue;
            }

            //x-small viewport corrections
            @media screen and (max-width: 425px) {
                li {
                    display: inline;
                    margin-left: 2px;
                }
                a {
                    font-size: 1.5rem;
                }
        }

            //x-small viewport corrections
            @media screen and (max-width: 400px) {
                display: none!important;
            }
        }

        #menu-back {
            display: inline-block;
            position: absolute;
            top: 0;
            right: 0;
            height: 30px;
            padding-top: .23rem;
            margin: 25px;
            text-decoration: none;
            font-size: 1.2rem;
            line-height: 1.5rem;
            font-weight: 500;
            color: $isc3-blue;
            outline: none;
            cursor: pointer;
        }
        #menu-back:hover {
            color: $isc3-dark-blue;
        }

        #menu-back::after {
            display: inline-block;
            font-family: 'Font Awesome 5 Free';
            content: "\f138";
            font-size: 1.2rem;
            font-weight: 600;
            color: $isc3-blue;
            margin-left: 0.5rem;
            vertical-align: middle;
            padding-bottom: 4px;
        }
        #menu-back:hover::after {
            color: $isc3-dark-blue;
        }
    }

    // nav-active, used by navigation.js
    #nav-section.nav-active {
        ul#menu-add, ul#menu-add-user, ul#menu-sm  {
            display: inline;
        }
        #btn-menu {
            background-color: $isc3-dark-blue;
        }
        #btn-menu::after {
            content: "\f00d" !important;
            font-size: 1.15rem;
            line-height: 1.15;
            position: relative;
            top: 1px;
            margin-left: 0.35rem !important;
        }
    }

    // nav-section 2nd Lvl, used by navigation.js
    #nav-section.nav-2nd-lvl {
        ul#menu-add,
        ul#menu-add-user{
            position: absolute;
            left: 0;
            right: auto;
        }
        ul#menu-sm{
            display: none !important;
        }
        #menu-back {
            display:inline;
        }
    }

    // nav-section boxshadow z-index-hack
    #nav-section::after {
        content: ' ';
        width: 100%;
        height: 8px;
        background-image: url("img/shadow.png");
        background-repeat: repeat;
        // background-repeat: repeat-y;
        position: absolute;
        left: 0;
        top: $nav-height-mob;
        z-index: 99;
    }

    .nav-path {
        @media only screen and (max-width: 992px) {
            display: none;
        }

        z-index: -1;
        top: 0;
        width: 100%;
        height: auto;
        padding-top: 7px;
        padding-bottom: 7px;
        background-color: $isc3-darker-blue;
        color: rgba(white,.85);
        font-weight: 400;
        .container {
            padding-left: 15px;
            padding-right: 15px;
            height: auto !important;
            a  {
                color: rgba(white,.85);
                margin-left: 0.5em;
                margin-right: 0.5em;
            }
            a:hover,
            a:active {
                color: rgba(white,1);
            }
        }
    }


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // 2.2.) INTERACTIONS
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////

    #nav-section {

        nav, #nav-toggle, #nav-toggle-js, ul#menu-add, ul#menu-add-user, #menu-back, ul#menu-sm {
            display: none;
        }

        // Interaction: Change to Close Icon on nav-toggle
        #nav-toggle:checked ~ #btn-menu {
            background-color: $isc3-dark-blue;
        }
        #nav-toggle:not(:checked) ~ #btn-menu {
            background-color: $isc3-blue;
        }
        #nav-toggle:checked ~ #btn-menu::after {
            content: "\f00d";
            font-size: 1.15rem;
            line-height: 1.15;
            position: relative;
            top: 1px;
            margin-left: 0.35rem;
        }

        #nav-toggle:checked ~ nav {
            display: block !important;
        }


        // Arrow & Page Animations
        ul#menu-main li.multilevel > a:hover::before {
            animation: bounce_left 0.5s ease-in-out infinite;
        }

        ul#menu-main li > a:hover::before,
        ul#menu-main li.li-active > a:hover::before {
            animation: pump 0.5s ease-in-out infinite;
        }



        #menu-back:hover::after {
            animation: bounce_right 0.5s ease-in-out infinite;
        }
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////////
// 3.) CODE FOR Desktop VIEWPORT
////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // 3.1.) LAYOUT Desktop 
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////

    @media screen and (min-width: 992px) {
        #nav-section {
            height: $nav-height-dsk;
            text-align: left;
            z-index: 99;

            .container{
                max-width: 720px;
            }

            #btn-menu {
                top: calc(#{$nav-height-dsk} - 40px);
                right: 15px;
                display: none;

            }

            #logo {
                position: relative;
                top: 10px;
                z-index: 9;
                width: 150px;
            }

            nav {
                width: 100%;
                position: absolute;
                top: 0px;
                right: 0;
                left: 0;
                padding: 0;
                background: none;
                display: block;
                height: $nav-height-dsk;
                overflow: visible;

                ul#menu-main {
                    width: 100%;
                    text-align: right;
                    margin: 0;
                    padding: 0 15px 0 0;
                    border-top: 0;
                    height: $nav-height-dsk;
                    overflow: hidden;
                    max-height: none;
                    font-size: 1.15em;
                    line-height: 1.75em;
                    letter-spacing: 0.025em;

                    .no-background {
                        background: none;
                    }
                    .hidden {
                        display: none !important;
                    }
                    .clicked-li > ul {
                        display: block;
                    }

                    li {
                        display: inline-block;
                        cursor: pointer;

                        padding: 10px 8px;
                        margin: 54px 0 0 0;

                        a, a:active, a:visited {
                            padding: 0 2px !important;
                            font-weight: 100;
                            line-height: 1em;
                            color: $isc3-dark-grey;
                        }
                        a:hover {
                            color: $isc3-blue;
                        }
                        a:active {
                            color: white;
                        }


                        ul li {
                            a, a:active, a:visited {
                                color: white;
                            }
                            a:hover, a:active {
                                color: $isc3-blue !important;
                            }

                        }
                    }
                    li.first-level:hover > a {
                        // background-color: $isc3-darker-blue;
                        // background-blend-mode: multiply;
                        color: $isc3-blue;
                    }
                    .li-active {
                        background: $isc3-darker-blue;
                        a, a:active, a:visited{
                            color: white;
                            font-weight: 100 !important;
                        }
                        a:hover {
                            color: $isc3-blue;
                        }
                    }

                    li ul {
                        text-align: center;
                        position: absolute;
                        left: 15px;
                        right: 15px;
                        top: $nav-height-dsk;
                        width: auto;
                        padding: 65px 25px 40px 25px;
                        background-color: rgba($isc3-darker-blue, 0.95);
                        // background-blend-mode: multiply;
                        display: none;
                        // background: pink;
                        overflow: visible;
                        height: auto !important;
                        letter-spacing: 0.05em;


                        li {
                            display: inline;
                            line-height: 3em;
                            margin: 0;

                            a, a:active, a:visited {
                                color: white;
                                white-space: wrap;
                            }
                        }
                        li:hover {
                            background: none;
                        }
                        li.multilevel ul {
                            position: absolute;
                            left: 0;
                            right: 0;
                            top: 0;
                        }

                        li.link-add {
                            position: relative;
                            display: block;
                            margin: 0;
                            padding-top: 40px;
                            padding-bottom: 20px;
                            border-top: solid 1px $isc3-blue;
                            line-height: 1em;

                            a {
                                color: white;
                                font-weight: 500 !important;
                                line-height: 1em;
                            }
                            a:active {
                                font-weight: 500 !important;
                            }
                        }

                    }
                    li.multilevel > a::before {
                        content: '\f13a';
                    }

                    li.multilevel > a:hover::before {
                        animation: bounce_down 0.5s ease-in-out infinite;
                    }
                }
            }


            ul#menu-add,
            ul#menu-add-user {
                position: absolute;
                display: block;
                top: 15px;
                right: 15px !important;
                left: auto !important;
                margin: 0;
                li:last-of-type {
                    margin-right: 0;
                }
                border: none;
            }
        }

        #nav-section.nav-active {
            #navbar {
                display: block !important;
            }
        }

        // nav-section 2nd Lvl, used by navigation.js
        #nav-section.nav-2nd-lvl,
        #nav-section.nav-3rd-lvl {
            #menu-back {
                display: none;
            }
            ul#menu-sm {
                display: block !important;
                position: absolute;
                top: calc(#{$nav-height-dsk} + 5px);
                left: 15px !important;
                right: auto;
                margin: 0;
                padding: 15px 25px;
                a {
                    font-size: 1.5em;
                }
            }
        }
        #nav-section.nav-3rd-lvl {
            #menu-back {
                display: block;
                position: absolute;
                top: calc(#{$nav-height-dsk} + 5px);
                right: 15px;
                left: auto;
                margin: 0;
                padding: 15px 25px;
            }
            #menu-back::after {
                content: "\f139";
            }
            #menu-back:hover::after {
                animation: bounce_up 0.5s ease-in-out infinite;
            }
        }


        // nav-section boxshadow z-index-hack
        #nav-section::after {
            top: $nav-height-dsk;
        }
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////////
// 4.) CODE FOR DESKTOP VIEWPORT
////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // 4.1.) LAYOUT DESKTOP +
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////

    @media screen and (min-width:992px) {
        #nav-section {
            .container {
                max-width: 960px;
            }
            nav {
                ul#menu-main {
                    font-size: 1.2em;
                    line-height: 1.5em;
                }
            }
        }
    }

    @media screen and (min-width:1200px) {
        #nav-section {
            .container {
                max-width: 1140px;
            }
        }
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////
// X.) LAB (Experimental Code)
////////////////////////////////////////////////////////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////////////////////////////////////////////////
